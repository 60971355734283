import ActiveCyberDefence from "../../images/icons/ActiveCyberDefence.jpg";
import OffensiveSecurity from "../../images/icons/OffensiveSecurity.jpg";
import DataSecurity from "../../images/icons/DataSecurity.jpg";

export const transformationInfo = [
  {
    id: "SOC",
    title: "Security Operations Center (SOC)",
    img: ActiveCyberDefence,
    tag: "Automated and eyes-on-the-glass managed cybersecurity service",
    path: "/service/soc"
  },
  {
    id: "EUC",
    title: "End User Computing (EUC)",
    img: OffensiveSecurity,
    tag: "End-user computing: VA, patch management, application management",
    path: "/service/euc"
  },
  {
    id: "AOS",
    title: "Add-On and Advanced Services (AOS)",
    img: DataSecurity,
    tag: "Add-on cybersecurity services: red-blue teaming, VA/PT, audits, custom security development.",
    path: "/service/aos"
  },
];

export const aboutCard = [
  {
    title: "Sustainability",
    content:
      "We focus on the most significant areas where our unique skills, resources and expertise can create a positive impact on current and future generations.",
  },
  {
    title: "Environment",
    content:
      "We're working to improve the environmental  performance of our operations and supporting our community to join us on the journey towards a sustainable and resilient future.",
  },
  {
    title: "Governance",
    content:
      "Operating with integrity, our corporate governance processes strengthen our corporate performance and accountability.",
  },
  {
    title: "Reporting",
    content:
      "We're tracking our progress against set goals and sharing our innovations and achievements through annual reporting.",
  },
];


export const SocailMediaLink = [

  {
    link: "https://www.facebook.com/CDCLLP/",
    icon: "facebook"
  },
  {
    link: "https://www.linkedin.com/company/cdcinc",
    icon: "linkedin"
  },
  {
    link: "https://twitter.com/cdcllp",
    icon: "twitter"
  },
  {
    link: "https://www.instagram.com/cdcllp/",
    icon: "instagram"
  },

]

export const teamName1 = [
  {
    name: "Major. Krishna Lal",
    position: "Chairman; Former Executive Director EY, Commander, Police Cyberdome",
  },
  {
    name: "Pradeep Ravi",
    position: "Strategic Business Leader; Managing Director, CDC India",
  },
  {
    name: "Suresh Kumar",
    position: "Head of MENA Business; Alumnus – ICAI, MIT-Sloan",
  },
];
export const teamName2 = [
  {
    name: "Suresh Kumar",
    position: "Head of MENA Business; Alumnus – ICAI, MIT-Sloan",
  },
  {
    name: "Nandu. R",
    position: "Chief Coder Chief Technology Officer",
  },
  {
    name: "Greg Edwards",
    position: "Chief Strategist US; CEO WatchPoint",
  },
];
export const teamName3 = [
  {
    name: "Greg Edwards",
    position: "Chief Strategist US; CEO WatchPoint",
  },
  {
    name: "Gopakumar. S",
    position: "Advisor; Rtd. Deputy Director, ISRO Vice Chair, IEEE",
  },
  {
    name: "Deepa Sarath",
    position: "Chief Operations Officer; Former HR Ops Leader, Intec, Alamy ",
  },
];
export const teamName4 = [
  {
    name: "Gopakumar. S",
    position: "Advisor; Rtd. Deputy Director, ISRO Vice Chair, IEEE",
  },
  {
    name: "Deepa Sarath",
    position: "Chief Operations Officer; Former HR Ops Leader, Intec, Alamy ",
  },
  {
    name: " Jayachandran C.S",
    position: "Chief of Marketing and Sales; Former Regional Sales Director HCL ",
  },
];
export const fullTeam = [
  {
    name: "Major. Krishna Lal",
    position: "Chairman; Former Executive Director EY, Commander, Police Cyberdome",
  },
  {
    name: "Pradeep Ravi",
    position: "Strategic Business Leader; Managing Director, CDC India",
  },
  {
    name: "Suresh Kumar",
    position: "Head of MENA Business; Alumnus – ICAI, MIT-Sloan",
  },

  {
    name: "Nandu. R",
    position: "Chief Coder Chief Technology Officer",
  },
  {
    name: "Greg Edwards",
    position: "Chief Strategist US; CEO WatchPoint",
  },
  {
    name: "Gopakumar. S",
    position: "Advisor; Rtd. Deputy Director, ISRO Vice Chair, IEEE",
  },
  {
    name: "Deepa Sarath",
    position: "Chief Operations Officer; Former HR Ops Leader, Intec, Alamy ",
  },
  {
    name: " Jayachandran C.S",
    position: "Chief of Marketing and Sales; Former Regional Sales Director HCL ",
  },
]

export const successStories1 = [
  {
    title: "Muscat Elec. Distr. Company, Oman.",
    dec: "Utility: SOC, VA/PT, Health Check, IT & OT"
  },
  {
    title: "KIMS Hospital, Oman, Qatar, Bahrain, UAE, IND.",
    dec: "Healthcare: SOC, VA/PT, Health Check, IT & OT "
  },
  {
    title: "St. Mary’s School, Dubai.",
    dec: "Edn: SOC, VA/PT, Health Check, IT"
  },
]
export const successStories2 = [
  {
    title: "Watchpoint Data, CR, IA, US.",
    dec: "MSSP: Managed SOC Service Delivery: 40K EPS"
  },
  {
    title: "Canauri, CR, IA, US.",
    dec: "SaaS: Security Product Development"
  },
  {
    title: "ProCircular, IC, IA, US.",
    dec: "MSSP: Managed SOC Service Delivery: 100K EP "
  },
]
export const successStories3 = [
  {
    title: "ProCircular, IC, IA, US.",
    dec: "MSSP: Managed SOC Service Delivery: 100K EP "
  },
  {
    title: "Insurance Client, East Coast, US.",
    dec: "Insurance: Managed EUC, VA-Patch Mgmt. "
  },
  {
    title: "I-exceed, East Coast, US.",
    dec: "Banking SaaS: Compliance, VA/PT. "
  },

]
export const successStories4 = [
  {
    title: "ECIL, IND.",
    dec: "Govt.Critical Sector: Managed SOC Service Delivery Custom, Made-in -Ind SOC / SIEM Platform Development "
  },
  {
    title: "Robinson Cargo, IND.",
    dec: "Shipping and logistics: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Ennoventure., IND.",
    dec: "Technology Startup: CDC-ON Managed SOC Subscription"
  },
]
export const successStories5 = [
  {
    title: "Ennoventure., IND.",
    dec: "Technology Startup: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Banking Finance Client., IND.",
    dec: "Cooperative Bank: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Police Cyberdome., IND.",
    dec: "Govt. LEA: CDC-ON Managed SOC Subscription"
  },

]
export const allsuccessStories = [

  {
    title: "Muscat Elec. Distr. Company, Oman.",
    dec: "Utility: SOC, VA/PT, Health Check, IT & OT"
  },
  {
    title: "KIMS Hospital, Oman, Qatar, Bahrain, UAE, IND.",
    dec: "Healthcare: SOC, VA/PT, Health Check, IT & OT "
  },
  {
    title: "St. Mary’s School, Dubai.",
    dec: "Edn: SOC, VA/PT, Health Check, IT"
  },
  {
    title: "Watchpoint Data, CR, IA, US.",
    dec: "MSSP: Managed SOC Service Delivery: 40K EPS"
  },
  {
    title: "Canauri, CR, IA, US.",
    dec: "SaaS: Security Product Development"
  },
  {
    title: "ProCircular, IC, IA, US.",
    dec: "MSSP: Managed SOC Service Delivery: 100K EP "
  },
  {
    title: "Insurance Client, East Coast, US.",
    dec: "Insurance: Managed EUC, VA-Patch Mgmt. "
  },
  {
    title: "I-exceed, East Coast, US.",
    dec: "Banking SaaS: Compliance, VA/PT. "
  },
  {
    title: "ECIL, IND.",
    dec: "Govt.Critical Sector: Managed SOC Service Delivery Custom, Made-in -Ind SOC / SIEM Platform Development "
  },
  {
    title: "Robinson Cargo, IND.",
    dec: "Shipping and logistics: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Ennoventure., IND.",
    dec: "Technology Startup: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Banking Finance Client., IND.",
    dec: "Cooperative Bank: CDC-ON Managed SOC Subscription"
  },
  {
    title: "Police Cyberdome., IND.",
    dec: "Govt. LEA: CDC-ON Managed SOC Subscription"
  },
]

export const Testimonials1 = [
  {
    name: "Aaron",
    designation: "CEO, Procircular",
    says: "We've noticed virtually zero interruption in your services, and I can only imagine the work it must take to mask the challenges you're facing behind the scenes..."
  },
  {
    name: "Brandon",
    designation: "CTO, Procircular",
    says: "We've been happy with Ciber Digita’s performance and I haven’t heard any issues from the team."
  },

]
export const Testimonials2 = [
  {
    name: "Adithya",
    designation: " Director, Robinsons",
    says: "We are extremely happy with the service levels that have been provided and will continue our relationship with Ciber Digita even after and get into a new contract"
  },
  {
    name: "Thomas",
    designation: "Project Director, I-exceed",
    says: "CDC delivered what was promised. They were accessible whenever contacted, we are willing to recommend CDC to others"
  },
]
export const Fulltestimonials = [
  {
    name: "Aaron",
    designation: "CEO, Procircular",
    says: "We've noticed virtually zero interruption in your services, and I can only imagine the work it must take to mask the challenges you're facing behind the scenes..."
  },
  {
    name: "Brandon",
    designation: "CTO, Procircular",
    says: "We've been happy with Ciber Digita’s performance and I haven’t heard any issues from the team."
  },
  {
    name: "Adithya",
    designation: " Director, Robinsons",
    says: "We are extremely happy with the service levels that have been provided and will continue our relationship with Ciber Digita even after and get into a new contract"
  },
  {
    name: "Thomas",
    designation: "Project Director, I-exceed",
    says: "CDC delivered what was promised. They were accessible whenever contacted, we are willing to recommend CDC to others"
  },
]