import React from "react";
import Banner from "../../utils/images/banner/Build Your Own SIEM-EDR Platform/SIEM-EDR- Banner.png";
import CoverImage from "../../utils/images/banner/Build Your Own SIEM-EDR Platform/SIEM-EDR-Image.png";
import BannerHeader from "../../components/header/BannerHeader";
import { MSSPsDetails } from "./content";

export default function SIEM_EDRPlatform() {
  return (
    <div>
      <BannerHeader
        title="Build Your Own SIEM-EDR Platform"
        tagline="Take a look at the CDC-ON® response to the rest of the EDR/XDR competition including multiple market leaders."
        image={Banner}
        backgroundColor="#00323D"
      />
      <div className="flex justify-center items-center flex-col">
        <h1 className="text-2xl mt-20 mb-5">
          Unique for
          <span className="font-bold">CDC-ON®</span>
        </h1>
        <p className="text-[#676767] mb-10">Build Your Own SIEM-EDR Platform</p>
        <img src={CoverImage} alt="" className="lg:w-[15%]  w-[40%]" />
      </div>
      <div className="md:p-5 p-2 shadow-lg md:mx-20 rounded-lg">
        <div className="bg-[#FAFAFA] rounded-lg p-2">
          <ul>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">1</span> Zero learning curve
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">2</span> Advanced Machine Learning
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">3</span> Zero Trust
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">4</span> Highly Scalable
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">5</span> A comprehensive solution
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">6</span> Fully customizable, build your
              own niche custom SOC service with:
            </li>
            <li className="border-b-2 md:px-10 py-2 grid grid-cols-3 gap-x-5">
              <span className="text-center cursor-pointer hover:bg-[#E4EBFF]">
                <h1 className="mb-2 font-semibold">CDC-ON® PLATFORM</h1>
                Integrates/replaces any SIEM, EDR, XDR, Antivirus, providing a
                full-service SOC platform custom built for your business.
              </span>
              <span className="text-center cursor-pointer hover:bg-[#E4EBFF]">
                <h1 className="mb-2 font-semibold">CDC-ON® PEOPLE</h1>
                CDC trained SOC L1/2 analyst FTEs can support client SOC on any
                industry standard any third-party platform, or on custom built
                CDC-ON® platform. Can cover all shift options, including
                holidays and weekends.
              </span>
              <span className="text-center cursor-pointer hover:bg-[#E4EBFF]">
                <h1 className="mb-2 font-semibold">CDC-ON® PROCESS</h1>
                Support industry standard process frameworks, regulations:
                MITRE, NIST, ISO 270001, ISA 62443, IEC 61850, PLC MODBUS,
                HIPAA, SOX, integrated with CDC-ON® SOC process.
              </span>
            </li>
            <li className="border-b-2 md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">7</span>{" "}
              <span className="font-semibold"> Code Level Customisation:</span>{" "}
              Bespoke Platform Build Custom Modules and Features
            </li>
            <li className=" md:px-10 py-2 cursor-pointer hover:bg-[#E4EBFF]">
              {" "}
              <span className="mr-16">8</span>{" "}
              <span className="font-semibold">
                {" "}
                Code / API Level Integration With:
              </span>{" "}
              Any platform including: Splunk, AlienVault, LogRhythm,Q Radar,
              Bitdefender, Sentinel One, Carbon Black etc. or can support SOC on
              custom-built CDC-ON® platform.
            </li>
          </ul>
        </div>
      </div>

      <div className="flex justify-center items-center flex-col my-20 text-center">
        <h1 className="text-2xl mt-20 mb-5 font-bold">MSSPs:</h1>
        <p className="text-[#676767] lg:w-max w-[80%]">
          Custom build your service: You can add your needs to this list. We
          will build it for you.
        </p>
      </div>
      <div className="p-5 shadow-lg md:mx-20 rounded-lg mt-10 mb-20">
        <div className="bg-[#FAFAFA] rounded-lg ">
          <ul>
            {MSSPsDetails.map(({ content }, index) => (
              <li
                className={`${
                  index === 0 ? null : `border-t-2`
                } lg:px-10 px-2 py-4 cursor-pointer hover:bg-[#E4EBFF] flex`}
                key={index}
              >
                {" "}
                <div className="lg:w-[6%] w-[10%]">{index + 1}</div>{" "}
                <div className="w-[90%]">{content}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
