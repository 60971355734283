import React from "react";
import ListCard from "../../../components/card/ListCard";
import {
  APMMonitoring_ROI,
  Application_Performance_Insights,
  DevSecOps_Monitoring,
} from "./content";
import coverImage from "../../../utils/images/banner/Success Stories/Img-4.png";

export default function APMMonitoring() {
  return (
    <div className="flex justify-center items-center flex-col my-32">
      <div className="text-center">
        <h1 className="text-2xl leading-10">
          DevSecOps & <span className="font-semibold"> APM </span> Monitoring
        </h1>
      </div>
      <img src={coverImage} alt="" className="lg:w-[15%] md:w-[35%] w-[60%] mt-5" />
      {/* imageConatiner */}
      <div></div>
      {/* imageConatiner */}
      <div className="backgroundAbout grid lg:grid-cols-3 grid-cols-1 mt-10 gap-10 py-5 lg:px-32 p-5">
        <ListCard
          title={"Application Performance Insights"}
          list={Application_Performance_Insights}
        />
        <ListCard title={"DevSecOps_Monitoring"} list={DevSecOps_Monitoring} />
        <ListCard title={"ROI"} list={APMMonitoring_ROI} />
      </div>
    </div>
  );
}
