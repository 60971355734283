import React from "react";
import AboutDec from "../about/AboutDec";
import AboutShort from "../about/AboutShort";
import Intro from "./Intro";
import TransformationCard from "./TransformationCard";

export default function Home() {
  return (
    <div>
      <Intro />
      <div className="lg:h-[80vh] py-10 lg:px-0 px-2 bg-[#f3f4f6]">
        <div className="text-center">
          <p className="text-primaryBlue font-bold text-lg">
            Transformation. Delivered.
          </p>
          <h1 className="text-black mt-5 font-bold text-2xl">
            We have the integrated expertise to meet all your
            <br />
            cybersecurity transformation needs.
          </h1>
          <h1 className="mt-10 text-2xl text-blue-700 font-bold">Our active cyber defence transformation solution</h1>
        </div>
        <div className="lg:h-[40vh] lg:mt-20 ">
          <TransformationCard />
        </div>
      </div>
      <AboutShort />
      <AboutDec />
    </div>
  );
}
