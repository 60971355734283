import React from "react";
import BannerHeader from "../../components/header/BannerHeader";
import Banner from "../../utils/images/banner/EDR Platform Comparison/EDR- Banner.png";
import coverImage from "../../utils/images/banner/EDR Platform Comparison/EDR-Image.png";
import { EDRP_Platform, EDRP_Platform2 } from "./content";

export default function EDRPlatform() {
  return (
    <div>
      <BannerHeader
        title="EDR Platform Comparison"
        tagline="Take a look at the CDC-ON® response to the rest of the EDR/XDR competition including multiple market leaders."
        image={Banner}
        backgroundColor="#0c003c"
      />
      <div className="flex justify-center items-center flex-col text-center">
        <h1 className="text-2xl my-20">
          CDC-ON®'s Response to{" "}
          <span className="font-bold">Market Leaders</span>
        </h1>
        <img src={coverImage} alt=""  className="lg:w-[15%]  w-[40%]"/>
      </div>
      <div className="backgroundAbout">
        <div class="container mx-auto px-4 sm:px-8">
          <div class="py-8">
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden bg-white p-2">
                <div className="tableHeader bg-[#6993FF] text-white grid grid-cols-4">
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 "></div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    {" "}
                    Market Leader 1
                  </div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    Market Leader 2
                  </div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    CDC-ON
                  </div>
                </div>
                <div className="grid grid-cols-4 text-sm">
                  <div className="p-2 text-center border-b-2 border-r border-l-2 border-gray-200 "></div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 "></div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 "></div>
                  <div className="p-2 text-center border-b-2 border-r-2 border-gray-200 ">
                    MSSPs', You name it, we will get it for you
                  </div>
                </div>
                {EDRP_Platform?.map(
                  ({
                    mainContent,
                    marketLeader1_row1,
                    marketLeader1_row2,
                    marketLeader1_row3,
                    marketLeader2_row1,
                    marketLeader2_row2,
                    marketLeader2_row3,
                    cdcon_row1,
                    cdcon_row2,
                    cdcon_row3,
                    noofrow,
                    cdcon_row,
                  }) => (
                    <div
                      className={`grid grid-cols-4 my-2 cursor-pointer hover:bg-[#F2F5FF] ${
                        noofrow === 2 ? `grid-rows-2` : `grid-rows-3`
                      }`}
                    >
                      <div
                        className={`p-2 text-center border-b-2 border-t-2 border-r border-l-2 border-gray-200   ${
                          noofrow === 2 ? `row-span-2` : `row-span-3`
                        }  flex justify-center items-center`}
                      >
                        <div className="bg-[#E0E7FD] md:h-full md:w-screen flex justify-center items-center text-left ">
                          <p className="headerwithmaincontent lg:text-base text-xs lg:p-0 p-2 w-[80%]">
                            {mainContent}
                          </p>
                        </div>
                      </div>
                      <div className="p-2 flex justify-center items-center text-center border-b-2 border-t-2  border-r  border-gray-200 lg:text-base text-xs">
                        {marketLeader1_row1}
                      </div>
                      <div className="p-2 flex justify-center items-center text-center border-b-2 border-t-2  border-r  border-gray-200 lg:text-base text-xs">
                        {marketLeader2_row1}
                      </div>
                      <div
                        className={`p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r-2 overflow-hidden border-gray-200  bg-[#F2F5FF]  lg:text-base text-xs ${
                          cdcon_row === "full" ? `row-span-3` : null
                        }`}
                      >
                        {cdcon_row1}
                      </div>
                      <div className="p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r  border-gray-200 lg:text-base text-xs ">
                        {marketLeader1_row2}
                      </div>
                      <div className="p-2 flex justify-center items-center text-center border-b-2 border-t-2  border-r  border-gray-200 lg:text-base text-xs ">
                        {marketLeader2_row2}
                      </div>
                      <div
                        className={`p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r-2  border-gray-200 lg:text-base text-xs bg-[#F2F5FF] ${
                          cdcon_row === "full" ? `hidden` : null
                        }`}
                      >
                        {cdcon_row2}
                      </div>
                      {noofrow === 3 ? (
                        <div className="p-2 flex justify-center items-center text-center border-b-2 border-t-2  border-r  border-gray-200 lg:text-base text-xs">
                          {marketLeader1_row3}
                        </div>
                      ) : null}
                      {noofrow === 3 ? (
                        <div className="p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r  border-gray-200 lg:text-base text-xs ">
                          {marketLeader2_row3}
                        </div>
                      ) : null}
                      {noofrow === 3 ? (
                        <div
                          className={`p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r-2  border-gray-200 lg:text-base text-xs bg-[#F2F5FF] ${
                            cdcon_row === "full" ? `hidden` : null
                          }`}
                        >
                          {cdcon_row3}
                        </div>
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div class="container mx-auto px-4 sm:px-8">
          <div class="py-8">
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden bg-white p-2">
                <div className="tableHeader bg-[#6993FF] text-white grid grid-cols-4">
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 "></div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    Market Leader 1
                  </div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    Market Leader 2
                  </div>
                  <div className="p-2 text-center border-b-2 border-r border-gray-200 ">
                    CDC-ON
                  </div>
                </div>

                {EDRP_Platform2?.map(
                  ({
                    mainContent,
                    marketLeader1_row1,
                    marketLeader1_heading,
                    marketLeader2_row1,
                    marketLeader2_heading,
                    cdcon_row1,
                  }) => (
                    <div
                      className={`grid grid-cols-4 my-2 cursor-pointer hover:bg-[#F2F5FF]`}
                    >
                      <div
                        className={`p-2 text-center border-b-2 border-t-2 border-r border-l-2 border-gray-200  flex justify-center items-center`}
                      >
                        <div className="bg-[#E0E7FD] md:h-full md:w-screen  flex justify-center items-center text-left ">
                          <p className="headertwowithmaincontent lg:text-base text-xs lg:p-0 p-2 w-[80%]">
                            {mainContent}
                          </p>
                        </div>
                      </div>
                      <div className="p-2 flex  flex-col justify-center items-center text-center border-b-2 border-t-2  border-r  lg:text-base text-xs ">
                        <h1 className="mb-2 font-bold">
                          {marketLeader1_heading}
                        </h1>
                        <p>{marketLeader1_row1}</p>
                      </div>
                      <div className="p-2 flex flex-col  justify-center items-center text-center border-b-2 border-t-2  border-r  border-gray-200 lg:text-base text-xs">
                        <h1 className="mb-2 font-bold">
                          {marketLeader1_heading}
                        </h1>
                        <p>{marketLeader2_row1}</p>
                      </div>
                      <div
                        className={`p-2 flex justify-center items-center text-center border-b-2  border-t-2 border-r-2  border-gray-200 lg:text-base text-xs bg-[#F2F5FF]`}
                      >
                        {cdcon_row1}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
