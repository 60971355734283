import React from "react";
import aboutbanner from "../../utils/images/banner/aboutbanner.jpg";

export default function AboutShort() {
  return (
    <div className="h-screen bg-blueShade relative overflow-hidden flex items-center">
      <div className=" absolute lg:-right-[20%] -right-[30%] lg:-top-[30%] -top-[20%] ">
        <img
          src={aboutbanner}
          alt=""
          className=" h-[80%] w-[80%] object-contain"
        />
      </div>
      <div className="text-white lg:text-left lg:pl-24 lg:px-0 lg:mt-0 mt-16 px-5 lg:w-[60%] text-center relative z-50">
        <h2 className="lg:text-3xl text-xl mb-10">
          Enabling your digital transformation securely
        </h2>
        <p className="lg:text-sm font-normal lg:leading-[28px] leading-[25px] text-xs">
          Ciber Digita Consultants LLC (CDC) is an integrated Cyber Security
          venture led by industry veterans, experts, and technologists, with
          accomplished track records in Digital Transformation, Cyber Security,
          Finance and Business.
        </p>
        <p className="lg:text-sm py-10 font-normal lg:leading-[28px] leading-[25px] text-xs">
          CDC aims to help operate, transform, protect, and future-proof
          businesses in the face of future security challenges.
        </p>
        <p className="lg:text-sm font-normal lg:leading-[28px] leading-[25px] text-xs">
          CDC is complemented by a strong product development team dedicated in
          building Cyber Security products and solutions.
        </p>
        <p className="lg:text-sm font-normal lg:leading-[28px] leading-[25px] text-xs mt-10">
          The CDC team and its product platform CDC-ON can help transform the
          security of any business ranging from Small and Medium Business to
          enterprises.
        </p>
        <button className="py-[7px] mt-10">Get Started</button>
      </div>
    </div>
  );
}
