import React from "react";
import SOCBanner from "../../utils/images/banner/SOC/service1.jpg";
import SOCMain from "../../utils/images/banner/SOC/SOC-img-1.png";
import SOCPC1 from "../../utils/images/banner/SOC/soc pc1.png";
import SOCPC2 from "../../utils/images/banner/SOC/soc pc2.png";
import CaseStudiesBanner from "../../utils/images/banner/SOC/SOC-img-2.png";
import BulletIcon from "../../utils/images/icons/Bullet-icon 1.png";
import External from "../../utils/images/icons/SOC/External-icon.png";
import IoT from "../../utils/images/icons/SOC/IoT-icon.png";
import Mobile_application from "../../utils/images/icons/SOC/Mobile application-icon.png";
import SAST_DAST from "../../utils/images/icons/SOC/SAST and DAST-icon.png";
import Secure_Cnfg from "../../utils/images/icons/SOC/Secure Cnfg-icon.png";
import Web_application from "../../utils/images/icons/SOC/Web application-icon.png";
import UBA_icon from "../../utils/images/icons/SOC/UBA-icon.png";
import Government from "../../utils/images/icons/SOC/Case Studies-Government-icon.png";
import EDR from "../../utils/images/icons/SOC/EDR, XDR-icon.png";
import Banking from "../../utils/images/icons/SOC/Case Studies-Banking-icon.png";
import Health from "../../utils/images/icons/SOC/Case Studies-Health-icon.png";
import Utility from "../../utils/images/icons/SOC/Case Studies-Utility-icon.png";
import {
  packageContentGold,
  packageContentPlatinum,
  packageContentSilver,
} from "./content";
import BannerHeader from "../../components/header/BannerHeader";

export default function SOC() {
  return (
    <div>
      <BannerHeader
        title="Security Operations Center (SOC)"
        tagline="Automated and Eyes-on-the-glass managed cybersecurity as a service (CaaS)."
        image={SOCBanner}
        backgroundColor="#100F2C"
      />
      <div className="px-10 mb-10">
        <div className="text-center mt-10">
          <h1 className="text-sm text-blue-500 mb-5">Overview</h1>
          <p className="text-lg">
            Tailor-made <span className="font-bold">SOC</span> Service Delivery{" "}
            <br />
            and Platform
          </p>
        </div>
        <div className="flex justify-center mt-10">
          <div className="flex flex-col justify-center items-center text-center w-[180px]">
            <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
              <img
                src={UBA_icon}
                alt=""
                className="w-[60%] h-[60%] object-contain"
              />
            </div>
            <p className="text-[10px] font-semibold mt-1">
              Advanced Detection & User Behavior Analytics (UBA)
            </p>
          </div>
        </div>
        <div className="flex justify-center md:flex-row flex-col">
          <div className="md:order-2 order-1 flex justify-center flex-col items-center text-center px-14">
            <img src={SOCMain} alt="" />
          </div>
          <div className="md:flex hidden justify-center items-center flex-col gap-y-10 md:order-1 order-2">
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={External} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Advanced Visualizations & Dashboards (SIEM)
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[180px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Web_application} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Alert Remediation Workflow Advanced Correlation E-mail, Cloud
                Security
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[180px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Mobile_application} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Availability and Performance Monitoring (NOC)
              </p>
            </div>
          </div>

          <div className="md:flex hidden justify-center items-center flex-col gap-y-10 order-3">
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={SAST_DAST} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Code-Level Customizable
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[120px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Secure_Cnfg} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Simple & Powerful Log Searching Capability
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[180px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={IoT} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Built-in Security Orchestration Automation & Response: (SOAR,
                Threat Intelligence)
              </p>
            </div>
          </div>

          <div className="md:hidden order-2 flex justify-center gap-5 mt-10">
            <div className="flex justify-center items-center flex-col gap-y-10 md:order-1 order-2">
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={External} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Advanced Visualizations & Dashboards (SIEM)
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Web_application} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Alert Remediation Workflow Advanced Correlation E-mail, Cloud
                  Security
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Mobile_application} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Availability and Performance Monitoring (NOC)
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center flex-col gap-y-10 order-3">
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={SAST_DAST} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Code-Level Customizable
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Secure_Cnfg} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Simple & Powerful Log Searching Capability
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={IoT} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Built-in Security Orchestration Automation & Response: (SOAR,
                  Threat Intelligence)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center lg:mb-10 lg:mt-0 mt-10">
          <div className="flex flex-col justify-center items-center text-center w-[180px]">
            <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
              <img src={EDR} alt="" />
            </div>
            <p className="text-[10px] font-semibold mt-1">
              Built/Integrate:
              <span className="font-bold">
                (EDR, XDR, Antivirus, Anti-ransomware, or any Security
                Tool/Module)
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="lg:min-h-[50vh] p-10 bg-backgroundBlueShade grid lg:grid-cols-2 grid-cols-1">
        <div>
          <h2 className="text-lg font-bold mb-2">SOC</h2>
          <small>
            Support for ANY technology. 24x7 <br /> Eyes-on-the-Glass Service:
            Blended FTE,and <br /> Managed SOC white-labeled service.
          </small>
          <hr className="my-10 bg-black" />
          <h2 className="text-lg font-bold mb-2">SOC Platform CDC-ON</h2>
          <small>
            Integrates with any SIEM, EDR, XDR to provide a common dashboard, or
            as a full-service active defense platform.
          </small>
          <p className="text-sm">
            <span className="font-bold">Tested and Certified by:</span>{" "}
            Standardization Testing and Quality Certification (STQC)
            Directorate.
          </p>
        </div>
        <div className="lg:mt-0 mt-10">
          <img
            src={SOCPC1}
            alt=""
            className="h-[300px] w-[800px] object-contain"
          />
        </div>
      </div>
      <div className=" p-10 grid lg:grid-cols-2 grid-cols-1">
        <div className="flex justify-center items-center lg:my-0 my-5">
          <img
            src={SOCPC2}
            alt=""
            className="h-[300px] w-[800px] object-contain"
          />
        </div>
        <div>
          <h2 className="text-lg font-bold mb-2">
            The <span className="font-extrabold">Blended FTE</span> and <br />{" "}
            Technology Agnostic Model
          </h2>
          <ul className="mt-4 grid gap-y-5">
            <li className="flex">
              <div className="mr-2 w-[18px] h-[18px] mt-2">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[18px] h-[18px] object-cover"
                />
              </div>
              <div>
                Service available with or without CDC-ON® platform Combines
                people, process, and technology
              </div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>Full-service blended FTE SOC analyst teams (L1 and L2)</div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>Available as managed, dedicated, or hybrid teams</div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>
                Technology agnostic design, implementation, and operation of SOC
              </div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>
                Integration with IT/OT/IoT devices and any SIEM platform, EDR,
                XDR
              </div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>Custom code and application development for integration</div>
            </li>
            <li className="flex">
              <div className="mr-2 w-[15px] h-[15px] mt-1">
                <img
                  src={BulletIcon}
                  alt=""
                  className="w-[15px] h-[15px] object-cover"
                />
              </div>
              <div>
                Covers all shift options, including holidays and weekends
              </div>
            </li>
            <li className="mt-5">
              <div className="ml-5">
                <p>
                  FTEs can support client SOC on any platform including:{" "}
                  <span className="font-semibold">
                    Splunk, AlienVault, LogRhythm, QRadar, Bitdefender, Sentinel
                    One, Carbon Black
                  </span>{" "}
                  etc. or can support SOC on custom-built{" "}
                  <span className="font-semibold">CDC-ON</span> platform.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#f1f1f1] pb-10">
        <div className="text-center py-10">
          <h1 className="text-xl font-extrabold mb-5">Case Studies</h1>
          <p className="text-blue-600 font-bold">Sectors</p>
        </div>
        <div className="py-3 lg:px-32 flex justify-center lg:flex-row flex-col items-center">
          <div className="grid gap-y-2">
            <div className="w-[220px] h-[120px] bg-white rounded-xl flex justify-center shadow-lg items-center flex-col cursor-pointer transition-all duration-100 hover:text-blue-600 hover:border-2 hover:border-blue-600">
              <img src={Government} alt="" />
              <h1 className="mt-2">Government</h1>
            </div>
            <div className="w-[220px] h-[120px] bg-white rounded-xl flex justify-center shadow-lg items-center flex-col cursor-pointer  transition-all duration-100 hover:text-blue-600 hover:border-2 hover:border-blue-600">
              <img src={Banking} alt="" />
              <h1 className="mt-2">Banking</h1>
            </div>
          </div>
          <div className="px-5 lg:py-0 py-14">
            <img
              src={CaseStudiesBanner}
              alt=""
              className="w-[300px] h-[300px] object-cover"
            />
          </div>
          <div className="grid gap-y-2">
            <div className="w-[220px] h-[120px] bg-white rounded-xl flex justify-center shadow-lg items-center flex-col cursor-pointer transition-all duration-100 hover:text-blue-600 hover:border-2 hover:border-blue-600">
              <img src={Health} alt="" />
              <h1 className="mt-2">Health</h1>
            </div>
            <div className="w-[220px] h-[120px] bg-white rounded-xl flex justify-center shadow-lg items-center flex-col cursor-pointer  transition-all duration-100 hover:text-blue-600 hover:border-2 hover:border-blue-600">
              <img src={Utility} alt="" />
              <h1 className="mt-2">Utility</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Package */}
      <div className="my-12 text-center lg:px-0 px-2">
        <h1 className="text-2xl font-semibold mb-3">
          CaaS SOC Premium Subscription Service
        </h1>
        <p className="text-sm font-semibold text-gray-600 mb-2">
          24x7 Eyes-on-the-Glass Service as a subscription service for
          end-clients
        </p>
        <p className="text-sm">
          Monitor for current cyber threats and protect your network,
          applications,
          <br /> and users Get real time alerting, and compliance reporting.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-8 md:px-28 px-5  mb-10">
        <div className="rounded-xl overflow-hidden shadow-lg">
          <header className="bg-blue-400 text-white text-center py-5">
            <h1 className="text-2xl font-semibold">Silver Tier</h1>
          </header>
          <div className="px-5">
            <div className="text-center my-5 border-b-2 border-gray-200 pb-5">
              <h1 className="text-lg font-bold">Annual Subscription </h1>
              {/* <p>Max 70 Assets </p> */}
              <p>24/7 Automated Security </p>
              <p>Monitoring</p>
            </div>
            <ul className="py-10 grid gap-4 lg:min-h-[110vh]">
              {packageContentSilver.map(({ points }) => (
                <li className="flex">
                  <div className="mr-2 w-[14px] h-[14px]">
                    <img
                      src={BulletIcon}
                      alt=""
                      className="w-[20px] h-[20px] object-cover"
                    />
                  </div>
                  <div className="w-[80%]">{points}</div>
                </li>
              ))}
            </ul>
            <div className="text-center py-5 border-t-2 border-gray-300">
              <button className="rounded-xl text-blue-600 bg-white blueBorder">
                Get Started
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-xl overflow-hidden shadow-lg">
          <header className="bg-blue-500 text-white text-center py-5">
            <h1 className="text-2xl font-semibold">Gold Tier</h1>
          </header>
          <div className="px-5">
            <div className="text-center my-5 border-b-2 border-gray-200 pb-5">
              <h1 className="text-lg font-bold">Annual Subscription </h1>
              <p>24/7 Automated and </p>
              <p>8/5 eyes-on-the-glass monitoring</p>
            </div>
            <ul className="py-10 grid gap-4 lg:min-h-[110vh]">
              {packageContentGold.map(({ points }) => (
                <li className="flex">
                  <div className="mr-2 w-[14px] h-[14px]">
                    <img
                      src={BulletIcon}
                      alt=""
                      className="w-[20px] h-[20px] object-cover"
                    />
                  </div>
                  <div className="w-[80%]">{points}</div>
                </li>
              ))}
            </ul>
            <div className="text-center py-5 border-t-2 border-gray-300">
              <button className="rounded-xl text-blue-600 bg-white blueBorder">
                Get Started
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-xl overflow-hidden shadow-lg">
          <header className="bg-blue-600 text-white text-center py-5">
            <h1 className="text-2xl font-semibold">Platinum Tier</h1>
          </header>
          <div className="px-5">
            <div className="text-center my-5 border-b-2 border-gray-200 pb-5">
              <h1 className="text-lg font-bold">Annual Subscription </h1>
              <p>24/7 Automated and </p>
              <p>24/7 eyes-on-the-glass monitoring</p>
            </div>
            <ul className="py-10 grid gap-4 lg:min-h-[110vh]">
              {packageContentPlatinum.map(({ points }) => (
                <li className="flex">
                  <div className="mr-2 w-[14px] h-[14px]">
                    <img
                      src={BulletIcon}
                      alt=""
                      className="w-[20px] h-[20px] object-cover"
                    />
                  </div>
                  <div className="w-[80%]">{points}</div>
                </li>
              ))}
            </ul>
            <div className="text-center py-5 border-t-2 border-gray-300">
              <button className="rounded-xl text-blue-600 bg-white blueBorder">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:px-28 px-5 mb-10">
        <div className="rounded-xl overflow-hidden shadow-lg text-center">
          <header className="bg-blue-700 text-white text-center py-5">
            <h1 className="text-2xl font-semibold">Diamond Tier</h1>
          </header>
          <div className="px-5">
            <div className="my-10">
              <p className="mb-5">
                Premium white-glove customizable SOC & cybersecurity services
                tailored to meet client requirements.
              </p>
              <p>
                While SMBs up to 1000 endpoints can opt for our managed CaaS,
                Enterprises and MSSPS, can opt for the Diamond tier service. We
                can support 20,000+ assets on one distributed quad-server
                architecture instance.
              </p>
            </div>
            <div className="text-center py-5 border-t-2 border-gray-300">
              <button className="rounded-xl text-blue-600 bg-white blueBorder">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Package End*/}
    </div>
  );
}
