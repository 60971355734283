import React from "react";

export default function AboutDec() {
  return (
    <div className=" lg:min-h-[70vh] py-10 lg:px-16 px-5">
      <div className="flex lg:flex-row flex-col justify-between items-center">
        <div>
          <h3 className="text-primaryBlue mb-5 text-base">
            About Ciber Digita Consultants LLC.
          </h3>
          <h1 className="lg:text-3xl text-xl font-bold text-black mb-5">
            In times of change, let us think for you
          </h1>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center my-14">
        <div className="text-center mb-5 py-10 rounded-lg fullShadow lg:w-[80%] w-[90%] bg-blue-100">
          <h1 className="mb-5 text-xl font-bold text-primaryBlue">
            Our Vision
          </h1>
          <p>
            The future is digital. At Ciber Digita Consultants, we’re here to
            secure it for you.
          </p>
        </div>
        <div className="text-center mb-5 py-10 rounded-lg fullShadow lg:w-[80%] w-[90%] bg-blue-50">
          <h1 className="mb-5 text-xl font-bold text-primaryBlue">
            Our Mission
          </h1>
          <p>Enterprise-class, affordable, complete cybersecurity for all.</p>
        </div>
      </div>

      {/* <div className="flex justify-center lg:items-start items-center lg:gap-5 gap-3 mt-16 flex-wrap">
        {aboutCard.map((data, index) => (
          <div
            className={`px-5 py-8 lg:w-[23%] w-[48%] h-max   bg-white rounded-lg ${
              index % 2 === 0 ? null : `lg:mt-12 mt-0`
            }`}
          >
            <h1 className="lg:text-xl text-sm font-bold mb-3">{data.title}</h1>
            <p className="lg:text-base text-xs">{data.content}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
}
