export const Platform_Comparison = [
    {
        validationParameters: "Search Engine",
        CDCON: true,
        splunkEnt: true,
        alienVault: false,
        logRhythm: false,
    },

    {
        validationParameters: "Agile drill down work flow and built in SOAR",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },

    {
        validationParameters: "	Index/ Feature/ Attribute level RBAC",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Built-in Threat Intelligence",
        CDCON: true,
        splunkEnt: false,
        alienVault: true,
        logRhythm: true,
    },
    {
        validationParameters: "Data Lake/ Custom data analytics/ Data modelling",
        CDCON: true,
        splunkEnt: true,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Pre-built rapid response actions with alerts for enrichment and mitigation",
        CDCON: true,
        splunkEnt: true,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "All-in-one agent for data collection and response actions",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Out-of-the-box matrices for EDR level inputs and actions within all-in-one agent to collect and generate any log.",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Pre-built use-cases and work-flow engine with MITRE/ Kill-chain Tagging",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "Threat detection and alert work-flow engine with story board for better incident representation",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Multitenancy and customisable event log/ alert meta data access using built-in RBAC",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "Hybrid SIEM out-of-the-box support and integration with any device platform: custom coding available",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "Lightweight, Master model architecture, multi location, improving packet inspection/ collection/ correlation",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "Out-of-the-box fine-tuning for endpoint data collection using all-in-one agent for improved efficiency",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "	Built in packages for compliance & security monitoring standards to enable value from day 1",
        CDCON: true,
        splunkEnt: true,
        alienVault: true,
        logRhythm: true,
    },
    {
        validationParameters: "	Alert investigation engine with out-of-the box integration for one-click reputation analysis (hash/ domain/ IP etc.)",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "	Alert investigation engine with out-of-the box integration for one-click reputation analysis (hash/ domain/ IP etc.)",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "Built in UI themes for user friendliness",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: true,
    },
    {
        validationParameters: "	All types of data input support using single collector, IT, OT, and IoT",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "	Solution Scalability based on customer requirements including code level customizations and feature build",
        CDCON: true,
        splunkEnt: false,
        alienVault: false,
        logRhythm: false,
    },
    {
        validationParameters: "High Search efficiency for large amounts of data",
        CDCON: true,
        splunkEnt: true,
        alienVault: true,
        logRhythm: true,
    },
    {
        validationParameters: "HOT/ Warm data persistence",
        CDCON: true,
        splunkEnt: true,
        alienVault: true,
        logRhythm: true,
    },
]

export const EDRP_Platform = [
    {
        noofrow: 3,
        mainContent: "Freedom to Choose Vs. One Size Fits All",
        marketLeader1_row1: "Customizable without the cost: Multi-site, multi-level architecture tailored to your org structure with no extra charge",
        marketLeader2_row1: "Flexibility that costs a fortune: Flat, limited tenancy with additional costs for limited customisation",
        cdcon_row1: "Customizable at code-level to suit the MSSP's business goals.",

        marketLeader1_row2: "Easy to learn, easy to become an expert: Manage your operations from one intuitive console",
        marketLeader2_row2: "A laborious learning curve: Requires navigation between Market Leader 2-native & Splunk-powered technology",
        cdcon_row2: "No learning curve",

        marketLeader1_row3: "Cloud-native, with options for more: Cloud-first SaaS, hybrid, and on-prem deployment & management available",
        marketLeader2_row3: "Confined to cloud-only",
        cdcon_row3: "MSSP decides the architecture, implementation, and delivery modes based on their business needs.",
    },
    {
        noofrow: 3,
        mainContent: "Time is Money: Faster, Better, Smarter Than Humans Alone",
        marketLeader1_row1: "The simplicity of Storyline™: Automatic correlation of benign and malicious telemetry, events are mapped to MITRE for faster investigation and response",
        marketLeader2_row1: "Less signal, more noise: “Continuous, comprehensive recording” translates to manual parsing, prioritization, and correlation of telemetry; especially challenging across reboots",
        cdcon_row1: "CDC-ON® SOAR: Automatic correlation of benign and malicious telemetry, events are mapped to MITRE and or to other tactics of the MSSPs' choice for faster investigation and response",

        marketLeader1_row2: "Real-time reconstruction: Machine-powered attack reconstruction generates focused, contextualized alerts for faster MTTR",
        marketLeader2_row2: "Human-powered, human-limited: Delayed, manual analysis introduces greater risk exposure",
        cdcon_row2: "CDC-ON® focuses on the shortest possible dwell time between attacks and the fastest MTTR",

        marketLeader1_row3: "Fully automated recovery: Patented automatic and 1-click remediation & rollback",
        marketLeader2_row3: "Rudimentary remediation: Implemented through API and custom code",
        cdcon_row3: "Single click remediation or map the remediation to the clients' change management process",
    },
    {
        noofrow: 2,
        mainContent: "Confidence and Continuity in the Cloud",
        marketLeader1_row1: "Scalable and sustainable: Runtime protection for containers, 10 Linux distributions",
        marketLeader2_row1: "Limited in Linux: Reduced feature support for 7 Linux distros, containers",
        cdcon_row1: "Containerized architecture, all Linux distributions or can custom build it for the MSSP",

        marketLeader1_row2: "Control and confidence: No DevOps / performance impact, scheduling and maintenance window support available",
        marketLeader2_row2: "Unplanned updates: OS kernel module dependencies may cause forced updates No maintenance window controls",
        cdcon_row2: "No, performance degradation, Quad server architecture and containers allow live updates with minimal maintenance windows",
    },
    {
        noofrow: 3,
        mainContent: "EDR That Over-delivers, Not Overwrites",
        marketLeader1_row1: "365 days: Malicious incident details",
        marketLeader2_row1: "180 days: Malicious incident details",
        cdcon_row1: "Limitation is determined by the MSSP not the product",

        marketLeader1_row2: "14 days: EDR data handles attacks like SUNBURST, upgradable to 365 days",
        marketLeader2_row2: "7 days: EDR data misses attacks like SUNBURST, overwrites data every 7 days; high comparative cost to upgrade beyond 7",
        cdcon_row2: "Limitation is determined by the MSSP not the product",

        marketLeader1_row3: "The data you need, on-demand: Cloud data lake streams in real time",
        marketLeader2_row3: "Delays for data: Data lake streaming takes hours or longer",
        cdcon_row3: "Real-time data availability",
    },
    {
        noofrow: 3,
        mainContent: "Where You’re a Name, Not a Number",
        marketLeader1_row1: "No security team left behind: Vigilance Respond & Respond Pro MDR offer accessible options for incident-driven triage, digital forensics, incident response, and threat resolution as needed for your organization",
        marketLeader2_row1: "Premium prices for standard services: Comparable capabilities require OverWatch Elite or Falcon Complete (Market Leader 2 highest-tier offerings)",
        cdcon_row1: "Fully customizable as per the MSSP needs.",

        marketLeader1_row2: "Actionable hunting & intelligence: WatchTower threat hunting service comes standard with Vigilance offerings",
        marketLeader2_row2: "Overpromised, under-delivered: Falcon Overwatch costs a premium for correlation-based services",
        cdcon_row2: "Under promise and over deliver. Your pie in the sky is what we want to deliver",

        marketLeader1_row3: "Fastest MDR on the planet: SOC expertise powered by platform automation",
        marketLeader2_row3: "MDR at human-speed: Only responds as quickly as its analysts, even with Falcon Complete",
        cdcon_row3: "Humans are still need, fastest automated response and or human-automated hybrid mode.",
    },
    {
        noofrow: 3,
        mainContent: "Ready. Real-time. Record-breaking.",
        marketLeader1_row1: "Quick and customizable (STAR, MITRE): Rules and policy updates are active and instantly responsive upon deployment to agents",
        marketLeader2_row1: "Lags and limitations: Behavioral rule, custom IOA, and policy changes can take up to 40 minutes to take effect, extending an attack’s lifespan and cost",
        cdcon_row1: "Instantaneous, customizable",

        marketLeader1_row2: "Richer context, fewer alerts: The most analytic detections in the MITRE ATT&CK Evaluation 3 years running, Singularity automatically consolidated 109 attack steps into just 9 alerts",
        marketLeader2_row2: "Manual and maintenance-heavy: A third as many analytic detections, despite all of the continuous tuning and manual correlation & analysis",
        cdcon_row2: "Automated and hybrid mode.",

        marketLeader1_row3: "Unparalleled Visibility: Works out-of-the-box, achieved record-breaking results in the ATT&CK evaluation with the highest analytic coverage",
        marketLeader2_row3: "Middle-of-the-road: 86% visibility with 17 missed detections, delays, and configuration changes with analytic detections for only 94 of 109 sub steps",
        cdcon_row3: "Full range of visibility combining the strengths of both Market Leader 1 and Crowd strike",
    },
    {
        noofrow: 3,
        cdcon_row: "full",
        mainContent: "Discovery as Dynamic as Your Attack Surface",
        marketLeader1_row1: "Passive and active: Network discovery, fingerprinting, and suspicious device blocking",
        marketLeader2_row1: "Passive-only: Rudimentary network discovery",
        cdcon_row1: "Combines all the strengths of these competing products and also the only product in the market that slows code level customization to meet the MSSPs' business needs",

        marketLeader1_row2: "Full functionality, one price: Unlimited Device Control and Firewall Control, no fine print",
        marketLeader2_row2: "Multiple modules, multiple costs: Complicated licensing for rudimentary capabilities",
        cdcon_row2: "",

        marketLeader1_row3: "Enterprise-ready: Broad OS support for Firewall Control and USB & Bluetooth Device Control, no reboot needed",
        marketLeader2_row3: "A minimally viable product: Windows-only Device and Firewall Control for USB (no Bluetooth), requires reboot to activate",
        cdcon_row3: "",
    },

]

export const EDRP_Platform2 = [
    {
        mainContent: "EDR",
        marketLeader1_heading: "Partial visibility",
        marketLeader1_row1: "Focused on process, file, network and user events.",
        marketLeader2_heading: "Full visibility",
        marketLeader2_row1: "Continuous, comprehensive recording captures raw events and related information that provides needed context - critical for hunting and investigations.",
        cdcon_row1: "Full visibility and highly customizable. Can go at the process, file and network level as well.",
    },
    {
        mainContent: "Deployment",
        marketLeader1_heading: "Reboot required",
        marketLeader1_row1: "Required endpoint downtime and restart for installation.",
        marketLeader2_heading: "Immediately Operational",
        marketLeader2_row1: "Deploys in minutes and is immediately operational - no reboot required.",
        cdcon_row1: "Instantaneous deployment, client can choose any deployment strategy",
    },
    {
        mainContent: "Proactive threat hunting",
        marketLeader1_heading: "Alert monitoring, triage & investigation",
        marketLeader1_row1: "Performs alert monitoring, triage and investigation on detected threats, not proactive threat hunting.",
        marketLeader2_heading: "24/7 proactive hunting",
        marketLeader2_row1: "Elite team of experts proactively hunt, investigate and advise on threat activity.",
        cdcon_row1: "24/7 full support available including L1/L2 analysts to augment the MSSP teams during grave yard shifts, holidays and week-ends",
    },
    {
        mainContent: "Threat intelligence",
        marketLeader1_heading: "File reputation",
        marketLeader1_row1: "Threat intelligence is limited to filehash reputation.",
        marketLeader2_heading: "Integrated intel",
        marketLeader2_row1: "Alerts are automatically enriched with Market Leader 2 threat intelligence including actor attribution, sandbox analysis and malware search for the threat and all known variants.",
        cdcon_row1: "Combines the strengths of both Market Leader 1 and Market Leader 2",
    },
    {
        mainContent: "Managed services",
        marketLeader1_heading: "Alert monitoring, triage & investigation",
        marketLeader1_row1: "Performs alert monitoring, triage and investigation on detected threats, not a full, end-to-end managed service.",
        marketLeader2_heading: "Fully managed endpoint protection",
        marketLeader2_row1: "Team of experts handles all aspects of endpoint security, from deployment, configuration, maintenance and monitoring, to alert handling, incident response and remediation.",
        cdcon_row1: "CDC-ON® is built by a Master MSSP for MSSPs.",
    },
]

export const MSSPsDetails = [
    {
        content: "Signature-based anti-malware protection "
    },
    {
        content: "Machine learning/algorithmic file analysis on the endpoint "
    },
    {
        content: "Machine learning for process activity analysis "
    },
    {
        content: "Process isolation "
    },
    {
        content: "Memory protection and exploit prevention "
    },
    {
        content: "Protection Against Undetected Malware "
    },
    {
        content: "Application whitelisting "
    },
    {
        content: "Local endpoint sandboxing/endpoint emulation "
    },
    {
        content: "Script, PE, or fileless malware protection "
    },
    {
        content: "Integration with on-premises network/cloud sandbox "
    },
    {
        content: "Real-time IoC search capabilities"
    },
    {
        content: "Retention period for full access to data"
    },
    {
        content: "Endpoint Firewall "
    },
    {
        content: "FW Learning Mode"
    },
    {
        content: "Automatically creates network traffic rules "
    },
    {
        content: "URL Filtering "
    },
    {
        content: "Host Based IPS "
    },
    {
        content: "USB device Control "
    },
    {
        content: "Full Device Control (Device Control based on Device Class product ID, Vendor ID and Device Name) "
    },
    {
        content: "Agent self-protection/remediation or alerting when there is an attempt to disable, bypass, or uninstall it "
    },
    {
        content: "Ransomware protection "
    },
    {
        content: "Protect/block ransomware "
    },
    {
        content: "VDI support "
    },
    {
        content: "Manage, and maintain, an application control database of known trusted applications? "
    },
    {
        content: "Multi-tenant cloud based service "
    },
    {
        content: "EPP management console available as an on-premises virtual or physical server/application"
    },
    {
        content: "Consolidated EPP management console to report on, manage, and alert for Windows macOS clients and mobile "
    },
    {
        content: "Data loss prevention "
    },
    {
        content: "Mobile Device Management "
    },
    {
        content: "Mobile threat Defense "
    },
    {
        content: "Vulnerability and patch management "
    },
    {
        content: "Network/Cloud sandboxing "
    },
    {
        content: "Security Orchestration, Analysis and Response (SOAR) Integration "
    },
    {
        content: "Network discovery tool "
    },
    {
        content: "Remote Access "
    },
    {
        content: "Remote scripting capabilities "
    },
    {
        content: "Default Deny Security with Default Allow Usability "
    },
    {
        content: "Run unknown files with Auto Containment Protection "
    },
    {
        content: "Create Virtual environment for any unknowns"
    },
    {
        content: "Virtualize file system, registry, COM on real endpoints "
    },
    {
        content: "Inter process Memory Access "
    },
    {
        content: "Windows/WinEvent Hook "
    },
    {
        content: "Device Driver Installations "
    },
    {
        content: "File Access/Modification/Deletion "
    },
    {
        content: "Registry Access/Modification/Deletion "
    },
    {
        content: "Network Connection"
    },
    {
        content: "URL Monitoring "
    },
    {
        content: "DNS Monitoring"
    },
    {
        content: "Process Creation "
    },
    {
        content: "Thread Creation"
    },
    {
        content: "Inter-Process Communication (Named Pipes, etc.) up to this "
    },
    {
        content: "Telemetry data itself can be extended in real time "
    },
    {
        content: "Event chaining and enrichment on the endpoints "
    },
    {
        content: "Adaptive Event Modelling "
    },
    {
        content: "Behavioral analysis (e.g. analysis over active memory, OS activity, user behavior, process/application behavior, etc.) "
    },
    {
        content: "Static analysis of files using capabilities such as machine learning (not including signature based malware detection)"
    },
    {
        content: "Time-series analysis "
    },
    {
        content: "Integration with automated malware analysis solutions (sandboxing) "
    },
    {
        content: "Threat Hunting interface or API for searching with YARA/REGEX/ElasticSearch/IOC "
    },
    {
        content: "Support for matching against private IOC "
    },
    {
        content: "Threat Intelligence integration"
    },
    {
        content: "Linking telemetry (observable data) to recreate a sequence of events to aid investigation "
    },
    {
        content: "Process/attack visualization "
    },
    {
        content: "Incident Response Platform or orchestration integration? "
    },
    {
        content: "Vulnerability reporting (ex. reporting on unpatched CVEs) "
    },
    {
        content: "Alert prioritization based on confidence, able to define thresholds for alerting. "
    },
    {
        content: "Alert prioritization factors system criticality "
    },
    {
        content: "Able to monitor risk exposure across environment organized by logical asset groups "
    },
    {
        content: "Reporting interface identifies frequent alerts that may be appropriate for automating response "
    },
    {
        content: "Remote scripting capabilities "
    },
    {
        content: "Quarantine and removal of files "
    },
    {
        content: "Kill processes remotely"
    },
    {
        content: "File retrieval "
    },
    {
        content: "Network isolation "
    },
    {
        content: "Filesystem snapshotting"
    },
    {
        content: "Memory snapshotting "
    },
    {
        content: "Manage customer endpoints and policies "
    },
    {
        content: "Incident Investigation & Response "
    },
    {
        content: "Preemptive containment "
    },
    {
        content: "Application profiling (AI support) "
    },
    {
        content: "Customizable policy creation "
    },
    {
        content: "Central monitoring of all endpoints"
    },
    {
        content: "Live remote inspection "
    },
    {
        content: "Tuning of monitoring rules for reduction of false positives "
    },
    {
        content: "Forensic analysis "
    },
    {
        content: "Cloud-based SIEM and Big Data Analytics "
    },
    {
        content: "Log data collection/correlation "
    },
    {
        content: "Threat intelligence integration "
    },
    {
        content: "Network profiling (AI support) "
    },
    {
        content: "Available as virtual or physical "
    },
    {
        content: "Integrated file analysis (cloud sandbox) "
    },
    {
        content: "Full packet capture "
    },
    {
        content: "Protocol analyzers numerous protocols such as TCP, UDP, DNS, DHCP, HTTP, HTTPS, NTLM, etc. w/full decoding capability "
    },
    {
        content: "Includes ready-to-use cloud application connectors for: "
    },
    {
        content: "Azure "
    },
    {
        content: "Google Cloud Platform "
    },
    {
        content: "Office 365 "
    },
    {
        content: "AWS "
    },
    {
        content: "Threat detection for cloud applications"
    },
    {
        content: "Log collection from cloud environments"
    },
    {
        content: "Generating actionable incident response from cloud application "
    },
    {
        content: "InHolistic security approach Combined network, endpoint, cloud "
    },
    {
        content: "Internal security sensor logs (IOCs) "
    },
    {
        content: "Expert Human Analysis"
    },
    {
        content: "ML & Behavioral Analysis"
    },
    {
        content: " Open source threat intelligence feeds"
    },
    {
        content: "Information sharing with industry"
    },
    {
        content: "Clean web (phishing sites, keyloggers, spam)"
    },
    {
        content: "Deep web (C&C servers, TOR browsers, database platform archives—pastebins)"
    },
    {
        content: "Cyber Adversary Characterization"
    },
    {
        content: "Security operations center (SOC) ISO27001 certified"
    },
    {
        content: "Dedicated cybersecurity expert and L1/2/3 resources"
    },
    {
        content: "Security monitoring "
    },
    {
        content: "Incident analysis"
    },
    {
        content: "Incident response (handling)"
    },
    {
        content: "Extensive threat hunting (scenario-based)"
    },

]