import React from "react";
import BulletIcon from "../../utils/images/icons/Bullet-icon 1.png";

export default function ListCard({ title, list }) {
  return (
    <div className="shadow-xl bg-white rounded-xl p-5 ">
      <h1 className="ml-2 text-lg font-bold">{title}</h1>
      <ul className="mt-8 grid gap-y-3">
        {list?.map(({ content }) => (
          <li className="flex gap-x-2 font-semibold">
            <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
              <img
                src={BulletIcon}
                alt=""
                className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
              />
            </div>
            <div className="lg:w-[100%] w-[90%]">{content}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
