export const AvailableIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="7" fill="#16C828" fill-opacity="0.18" />
        <rect x="3.61719" y="10.0957" width="1.85379" height="7.26928" rx="0.926897" transform="rotate(-44.6797 3.61719 10.0957)" fill="#00C514" />
        <rect x="15.4551" y="4.64453" width="1.85379" height="12.2814" rx="0.926897" transform="rotate(40.0253 15.4551 4.64453)" fill="#00C514" />
    </svg>


)
export const NotAvailableIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="7" fill="#FF2E2E" fill-opacity="0.18" />
        <rect x="5" y="6.31152" width="1.8547" height="12.2874" rx="0.927352" transform="rotate(-45 5 6.31152)" fill="#FF2E2E" />
        <rect width="1.8547" height="12.2874" rx="0.927352" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15 6.31152)" fill="#FF2E2E" />
    </svg>


)
export const MailIcon = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49981 0.46218C9.1921 0.298104 8.84873 0.21228 8.5 0.21228C8.15127 0.21228 7.8079 0.298104 7.50019 0.46218L1.12519 3.86218C0.785273 4.04342 0.501019 4.31366 0.302828 4.64398C0.104636 4.9743 -3.85121e-05 5.35228 1.06291e-08 5.73749V6.07112L7.12831 10.2489L8.5 9.48599L9.87169 10.2489L17 6.07112V5.73749C17 5.35228 16.8954 4.9743 16.6972 4.64398C16.499 4.31366 16.2147 4.04342 15.8748 3.86218L9.49981 0.46218ZM17 7.30256L10.9512 10.8481L17 14.2077V7.30256ZM16.9373 15.3892L8.5 10.7015L0.0626875 15.3892C0.177441 15.8493 0.442788 16.2578 0.816499 16.5497C1.19021 16.8416 1.65081 17.0001 2.125 17H14.875C15.3492 17.0001 15.8098 16.8416 16.1835 16.5497C16.5572 16.2578 16.8226 15.8493 16.9373 15.3892ZM1.06291e-08 14.2088L6.04881 10.8481L1.06291e-08 7.30256V14.2088Z" fill="#2A65FF" />
    </svg>


)
export const PhoneIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00234 0.511452C2.18825 0.336741 2.41149 0.20121 2.65726 0.113842C2.90303 0.0264737 3.16573 -0.0107373 3.42793 0.00467495C3.69014 0.0200872 3.94588 0.0877706 4.17819 0.20324C4.4105 0.31871 4.61409 0.479328 4.77546 0.674452L6.68265 2.98045C7.03221 3.40345 7.15546 3.95445 7.01734 4.47445L6.43615 6.66445C6.4061 6.77788 6.40772 6.8967 6.44086 7.00936C6.474 7.12203 6.53753 7.22471 6.62527 7.30745L9.23584 9.76445C9.32385 9.84721 9.43314 9.90709 9.55305 9.93829C9.67296 9.96948 9.79941 9.97092 9.92009 9.94245L12.2459 9.39545C12.5186 9.33129 12.8031 9.32631 13.0781 9.38088C13.3531 9.43545 13.6114 9.54815 13.8333 9.71045L16.2834 11.5045C17.1642 12.1495 17.245 13.3745 16.4566 14.1155L15.358 15.1495C14.5717 15.8895 13.3966 16.2145 12.3012 15.8515C9.49741 14.923 6.95175 13.4123 4.85302 11.4315C2.74852 9.45647 1.14342 7.06091 0.156774 4.42245C-0.227851 3.39245 0.117461 2.28545 0.903711 1.54545L2.00234 0.511452Z" fill="#2A65FF" />
    </svg>



)