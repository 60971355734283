import AboutmainBanner from "../../utils/images/banner/AboutUs/About US- Banner.png";
import PeopleBanner from "../../utils/images/banner/AboutUs/About Us People.png";
import TechnologyBanner from "../../utils/images/banner/AboutUs/About Us Technology.png";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import {
  fullTeam,
  teamName1,
  teamName2,
  teamName3,
  teamName4,
} from "../../utils/data/content/Content";

export default function AboutUS() {
  return (
    <div>
      <div
        className={`lg:h-[70vh] h-[50vh] flex lg:justify-between justify-center items-center lg:px-14 relative overflow-hidden`}
        style={{ background: "#1C1604" }}
      >
        <div className="lg:pl-8 relative z-30 lg:text-left text-center">
          <h1 className="lg:text-4xl text-xl text-white font-bold mb-4">
            Why CDC
          </h1>
          <p className="text-white font-normal lg:text-lg text-sm lg:w-[65%]">
            Ciber Digita Consultants is a full solution Security Operations
            Centre (SOC) active defense company providing IT/OT managed
            cybersecurity as a service (CaaS) using its own platform CDC-ON or
            on any platform chosen by the client. The services are delivered
            from its ISO 27001 Certified SOC facility run by highly trained
            personnel.
          </p>
          <h1 className="lg:text-4xl text-xl text-white font-bold mb-4 mt-8">
            Our Aim
          </h1>
          <p className="text-white font-normal lg:text-lg text-sm lg:w-[65%]">
          The main aim of our managed CaaS is to reduce the Dwell Time of an attack by fast response
          </p>
        </div>
        <div className="lg:h-[60vh] lg:top-0 lg:right-0 absolute top-5 md:-right-[5%] -right-[40%] h-[40vh] scale-105">
          <img src={AboutmainBanner} alt="" className="h-full object-cover" />
        </div>
      </div>
      <div className="text-2xl flex flex-col justify-center text-center items-center my-24 gap-y-6">
        <h1>
          We cover the three cornerstones <br /> of an active cyber defence
          Solution -
        </h1>
        <p className="font-bold">Technology, People, and Process.</p>
      </div>
      <div className="bg-backgroundBlueShade grid lg:grid-cols-2 grid-cols-1">
        <div className="flex justify-center items-center lg:mt-0 mt-10">
          <img src={TechnologyBanner} alt="" className="w-[50%]" />
        </div>
        <div className="p-14">
          <h1 className="text-2xl font-bold mb-8">Technology</h1>
          <p className="leading-8">
            CDC-ON: Our large technology stack consists of several components
            including a Next Generation SOC platform that has Advanced
            Visualisations & Dashboards (SIEM); Alert Remediation Workflow &
            Advanced Correlation (email & cloud security); Availability and
            Performance monitoring (NOC); Simple and powerful log searching
            capability; Built in Security Orchestration Automation & Response
            (SOAR & Threat intelligence); Advanced Detection & User Behavior
            Analysis (UBA); Extended Detection and Response with EDR,
            anti-ransomware technologies (XDR, EDR) etc.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1">
        <div className="lg:p-24 p-14">
          <h1 className="text-2xl font-bold  mb-5">People</h1>
          <p className="leading-8 mb-10">
            CDC trained SOC L1/2 analyst FTEs can support client SOC on any
            industry standard any third-party platform, or on custom built
            CDC-ON platform. Can cover all shift options, including holidays and
            weekends.
          </p>
          <hr />
          <h1 className="text-2xl font-bold mt-16 mb-5">Process</h1>
          <p className="leading-8">
            Support process frameworks, standards, regulations such as:{" "}
            <span className="font-bold">
              MITRE, NIST, ISO 270001, ISA 62443, IEC 61850, PLC MODBUS, HIPAA,
              SOX, RBI cybersecurity framework
            </span>{" "}
            etc.
          </p>
        </div>
        <div className="flex justify-center items-center lg:mb-0 mb-10">
          <img src={PeopleBanner} alt="" className="w-[50%]" />
        </div>
      </div>

      <div>
        <div className="flex flex-col justify-center items-center py-16">
          <h1 className="text-2xl font-bold">Meet the Team</h1>
          <p className="text-[#6993FF] mt-8">Executive Team</p>
        </div>
        <div className="backgroundAbout mb-10">
          <div className="md:block hidden">
            <Carousel showThumbs={false} infiniteLoop={true}>
              <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
                {teamName1.map(({ name, position }, index) => (
                  <div
                    className="shadow-xl p-5 text-center  lg:h-[180px] md:h-[250px] rounded-xl bg-white"
                    key={index}
                  >
                    <h1 className="font-bold mb-3 text-xl mt-6 ">{name}</h1>
                    <p>{position}</p>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
                {teamName2.map(({ name, position }, index) => (
                  <div
                    className="shadow-xl p-5 text-center  lg:h-[180px] md:h-[250px] rounded-xl bg-white"
                    key={index}
                  >
                    <h1 className="font-bold mb-3 text-xl mt-6 ">{name}</h1>
                    <p>{position}</p>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
                {teamName3.map(({ name, position }, index) => (
                  <div
                    className="shadow-xl p-5 text-center  lg:h-[180px] md:h-[250px] rounded-xl bg-white"
                    key={index}
                  >
                    <h1 className="font-bold mb-3 text-xl mt-6 ">{name}</h1>
                    <p>{position}</p>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
                {teamName4.map(({ name, position }, index) => (
                  <div
                    className="shadow-xl p-5 text-center  lg:h-[180px] md:h-[250px] rounded-xl bg-white"
                    key={index}
                  >
                    <h1 className="font-bold mb-3 text-xl mt-6 ">{name}</h1>
                    <p>{position}</p>
                  </div>
                ))}
              </div>
            </Carousel>
          </div>
          <div className="md:hidden block">
            <Carousel showThumbs={false}>
              {fullTeam.map(({ name, position }, index) => (
                <div
                  className="grid  grid-cols-1 py-10 px-10 gap-5 justify-center items-center"
                  key={index}
                >
                  <div className="shadow-xl p-5 text-center  h-[150px] rounded-xl bg-white">
                    <h1 className="font-bold mb-3 text-xl ">{name}</h1>
                    <p>{position}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
