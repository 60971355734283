import React, { useEffect, useState } from "react";
import { navMenu } from "../../utils/data/Menus/navMenu";
import "./nav.scss";
import { Link, useLocation } from "react-router-dom";
import useHeaderColor from "../../utils/hooks/useHeaderColor";
import Logo from "../../utils/images/Logo/logo.png";
import LogoWhite from "../../utils/images/Logo/logoWhite.png";

export default function NavBar() {
  const [isDropdown, setisDropdown] = useState(false);
  const [submenuID, setSubmenuID] = useState("m001");
  const [ismenuOpen, setismenuOpen] = useState(false);
  const [issubmenu, setissubmenu] = useState(false);

  const headerColor = useHeaderColor();
  const location = useLocation();

  const dropdownActive = (id) => {
    if (id === "m003") {
      setisDropdown("m003");
    } else if (id === "m004") {
      setisDropdown("m004");
    }
  };
  useEffect(() => {
    switch (location.pathname) {
      case "/service/soc":
      case "/service/aos":
      case "/service/euc":
      case "/service/success_stories":
        setSubmenuID("m003");
        setissubmenu(true);
        break;
      case "/platform/siemplatform":
      case "/platform/edrplatform":
      case "/platform/siemedrplatform":
      case "/platform/technicaldocumentation":
        setSubmenuID("m004");
        setissubmenu(true);
        break;
      case "/aboutus":
        setSubmenuID("m002");
        setissubmenu(true);
        break;

      default:
        setissubmenu(false);
    }
  }, [location]);

  return (
    <div className="fixed top-0 z-[500] w-full ">
      <nav
        className="flex justify-between lg:px-20 h-20 px-5 items-center relative duration-800 transition ease-out"
        style={{ background: headerColor }}
        onMouseLeave={() => setisDropdown(false)}
      >
        {/* Logo Section */}
        <section>
          <Link to="/">
            <div className="h-[60px] w-[180px]">
              <img
                src={
                  issubmenu
                    ? headerColor !== `white`
                      ? LogoWhite
                      : Logo
                    : Logo
                }
                className="h-[100%] w-[100%] object-contain"
                alt=""
              />
            </div>
          </Link>
        </section>
        <section className="lg:block hidden relative">
          <ul className="flex justify-around gap-x-10">
            {navMenu.map(({ mainMenu, id, path }) => (
              <li
                key={id}
                onClick={() => setSubmenuID(id)}
                onMouseOver={() => dropdownActive(id)}
                className={`cursor-pointer capitalize font-semibold ${
                  submenuID === id ? `text-primaryBlue font-semibold` : null
                } ${
                  issubmenu
                    ? headerColor !== `white`
                      ? `text-white`
                      : `black`
                    : `black`
                }`}
              >
                <Link to={path} className="hover:text-primaryBlue">
                  {mainMenu}
                </Link>
              </li>
            ))}
          </ul>
          {/* Dropdown */}
          {isDropdown === "m003" ? (
            <div className=" absolute top-10 right-[20px] rounded-xl bg-white text-gray-600 text-center w-[280px] p-2 text-sm shadow-md">
              <ul className="grid gap-y-1">
                <li className="cursor-pointer hover:font-semibold hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link onClick={() => setSubmenuID("m003")} to="service/soc">
                    Security Operations Center (SOC)
                  </Link>
                </li>
                <li className="cursor-pointer py-2 hover:font-semibold hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link onClick={() => setSubmenuID("m003")} to="service/euc">
                    End User Computing (EUC)
                  </Link>
                </li>
                <li className="cursor-pointer hover:font-semibold hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link onClick={() => setSubmenuID("m003")} to="service/aos">
                    Add-On and Advanced Services (AOS)
                  </Link>
                </li>
                <li className="cursor-pointer hover:font-semibold hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link
                    onClick={() => setSubmenuID("m003")}
                    to="service/success_stories"
                  >
                    Success Stories
                  </Link>
                </li>
              </ul>
            </div>
          ) : isDropdown === "m004" ? (
            <div className=" absolute top-10  -right-[80px] rounded-xl bg-white text-gray-600 text-center w-[250px] p-2 text-sm shadow-md">
              <ul className="grid gap-y-3">
                <li className="cursor-pointer  hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link
                    onClick={() => setSubmenuID("m004")}
                    to="platform/siemplatform"
                  >
                    SIEM Platform Comparison
                  </Link>
                </li>
                <li className="cursor-pointer  hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link
                    onClick={() => setSubmenuID("m004")}
                    to="platform/edrplatform"
                  >
                    EDR Platform Comparison
                  </Link>
                </li>
                <li className="cursor-pointer  hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link
                    onClick={() => setSubmenuID("m004")}
                    to="platform/siemedrplatform"
                  >
                    Build Your Own SIEM-EDR Platform
                  </Link>
                </li>
                <li className="cursor-pointer  hover:bg-primaryBlue hover:text-white hover:p-2 hover:rounded-lg">
                  <Link
                    onClick={() => setSubmenuID("m004")}
                    to="/platform/technicaldocumentation"
                  >
                    Technical Documentation
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
        </section>
        <section className="lg:block hidden">
          <button
            onClick={() =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              })
            }
          >
            Contact
          </button>
        </section>
        {/* toggle menu icon */}
        <div
          className="lg:hidden block cursor-pointer text-2xl"
          onClick={() => setismenuOpen(!ismenuOpen)}
        >
          <ion-icon name={`${ismenuOpen ? `close` : `menu`}`}></ion-icon>
        </div>

        <div
          className={
            ismenuOpen
              ? "scale-100 bg-primaryShade ease-out duration-200 absolute top-20 inset-x-0 p-2 transition transform origin-top-left lg:hidden"
              : "opacity-0 hidden scale-95 absolute top-20 inset-x-0 p-2 transition transform origin-top-left lg:hidden"
          }
        >
          <ul
            className="grid gap-3 pt-5 pb-2 pl-3"
            onClick={() => setismenuOpen(!ismenuOpen)}
          >
            {navMenu?.map(({ mainMenu, id, path }) => (
              <div key={id} className="">
                <li className={`cursor-pointer uppercase`}>
                  {mainMenu === "Services" ? null : (
                    <Link to={path} className="hover:text-primaryBlue">
                      {mainMenu}
                    </Link>
                  )}
                </li>
              </div>
            ))}
            <li className="py-2 font-semibold">Services</li>
            <li className="cursor-pointer hover:font-semibold">
              <Link onClick={() => setSubmenuID("m003")} to="service/soc">
                SOC
              </Link>
            </li>
            <li className="cursor-pointer py-2 hover:font-semibold">
              <Link onClick={() => setSubmenuID("m003")} to="service/euc">
                EUC
              </Link>
            </li>
            <li className="cursor-pointer hover:font-semibold">
              <Link onClick={() => setSubmenuID("m003")} to="service/aos">
                AOS
              </Link>
            </li>
            <li className="cursor-pointer hover:font-semibold">
              <Link
                onClick={() => setSubmenuID("m003")}
                to="service/success_stories"
              >
                Success Stories
              </Link>
            </li>
            <li className="py-2 font-semibold">Platform</li>
            <li className="cursor-pointer hover:font-semibold">
              <Link
                onClick={() => setSubmenuID("m004")}
                to="platform/siemplatform"
              >
                SIEM Platform Comparison
              </Link>
            </li>
            <li className="cursor-pointer py-2 hover:font-semibold">
              <Link
                onClick={() => setSubmenuID("m004")}
                to="platform/edrplatform"
              >
                EDR Platform Comparison
              </Link>
            </li>
            <li className="cursor-pointer hover:font-semibold">
              <Link
                onClick={() => setSubmenuID("m004")}
                to="platform/siemedrplatform"
              >
                Build Your Own SIEM-EDR Platform
              </Link>
            </li>
            <li className="cursor-pointer hover:font-semibold">
              <Link
                onClick={() => setSubmenuID("m004")}
                to="/platform/technicaldocumentation"
              >
                Technical Documentation
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
