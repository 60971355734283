import AboutUS from "../../pages/about/AboutUS";
import Home from "../../pages/home/Home";
import EDRPlatform from "../../pages/platform/EDRPlatform";
import SIEMPlatform from "../../pages/platform/SIEMPlatform";
import SIEM_EDRPlatform from "../../pages/platform/SIEM_EDRPlatform";
import TechnicalDocumentation from "../../pages/platform/TechnicalDocumentation";
import AOS from "../../pages/service/AOS";
import EUC from "../../pages/service/EUC";
import SOC from "../../pages/service/SOC";
import SuccessStories from "../../pages/service/Success Stories/SuccessStories";


export const routes = [
  {
    layout: "pages",
    pages: [
      {
        name: "home",
        path: "/",
        element: <Home />,
        isPrivate: false,
      },
      {
        name: "home",
        path: "/*",
        element: <Home />,
        isPrivate: false,
      },
      {
        name: "soc",
        path: "service/soc",
        element: <SOC />,
        isPrivate: false,
      },
      {
        name: "aos",
        path: "service/aos",
        element: <AOS />,
        isPrivate: false,
      },
      {
        name: "euc",
        path: "service/euc",
        element: <EUC />,
        isPrivate: false,
      },
      {
        name: "Success Stories",
        path: "service/success_stories",
        element: <SuccessStories />,
        isPrivate: false,
      },
      {
        name: "aboutus",
        path: "/aboutus",
        element: <AboutUS />,
        isPrivate: false,
      },
      {
        name: "SIEMPlatform",
        path: "/platform/siemplatform",
        element: <SIEMPlatform />,
        isPrivate: false,
      },
      {
        name: "EDRPlatform",
        path: "/platform/edrplatform",
        element: <EDRPlatform />,
        isPrivate: false,
      },
      {
        name: "SIEM_EDRPlatform",
        path: "/platform/siemedrplatform",
        element: <SIEM_EDRPlatform />,
        isPrivate: false,
      },
      {
        name: "TechnicalDocumentation",
        path: "/platform/technicaldocumentation",
        element: < TechnicalDocumentation />,
        isPrivate: false,
      },
    ],
  },
];
