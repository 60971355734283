import React from "react";
import { Link } from "react-router-dom";
import {
  SocailMediaLink,
  transformationInfo,
} from "../utils/data/content/Content";
import { footerMenu } from "../utils/data/Menus/navMenu";
import footerlogo from "../utils/images/Logo/logoWhite.png";
import BulletIcon from "../utils/images/icons/Bullet-icon 1.png";
import { MailIcon, PhoneIcon } from "../utils/images/icons/icons";

export default function Footer() {
  return (
    <div className="bg-blueShade md:min-h-[30vh]">
      <div className=" text-white p-10 grid md:grid-cols-3 grid-cols-1">
        <div className="">
          <div className="w-[200px] h-[50px]">
            <Link to="/">
              <img
                src={footerlogo}
                alt=""
                className="w-[100%] h-[100%] object-contain"
              />
            </Link>
          </div>
          <ul className="mt-14 ml-8 grid gap-y-4">
            <li className="flex gap-x-3 cursor-pointer hover:text-hoverBlue">
              <MailIcon />
              contact@cdc-on.me
            </li>
            <li className="flex gap-x-3 cursor-pointer hover:text-hoverBlue">
              <PhoneIcon />
              +971 52 235 0195 (M), + 971 4 8830479 (L)
            </li>
            <li className="flex gap-x-3 cursor-pointer hover:text-hoverBlue">
              <PhoneIcon /> +91 95678 40221
            </li>
          </ul>
        </div>
        <div className="md:ml-0 md:my-0 ml-4 my-4  lg:ml-16 lg:text-left md:text-center">
          <ul className="mt-6 grid gap-y-4">
            {footerMenu.map(({ mainMenu, id, path }) => (
              <li
                key={id}
                className={`cursor-pointer capitalize text-sm mb-1 hover:text-hoverBlue`}
              >
                <Link to={path}>{mainMenu}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:ml-0 md:mb-5 ml-4 mt-5 mb-5">
          <ul>
            <li className="font-semibold tracking-widest text-blue-600 mb-5">
              Quick Links
            </li>
            {transformationInfo.map(({ title, img, tag, path }) => (
              // <li className="font-light cursor-pointer mb-[5px] text-sm">{title}</li>
              <li className="flex">
                <div className="mr-2 w-[14px] h-[14px]">
                  <img
                    src={BulletIcon}
                    alt=""
                    className="w-[17px] h-[17px] object-cover mt-1"
                  />
                </div>
                <div className="w-[80%] grid gap-y-5">
                  <Link
                    to={path}
                    className="md:text-base text-sm mb-5 hover:text-hoverBlue"
                  >
                    {title}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex justify-between md:flex-row md:pb-0 pb-5 flex-col md:px-16 px-14 text-white mt-12">
        <div>
          <p className="text-sm">
            © 2023 Ciber Digita Consultants LLC. All rights reserved.
          </p>
        </div>
        <div className="flex gap-x-5 md:mt-0 mt-5">
          {SocailMediaLink.map(({ link, icon }, index) => (
            <div
              key={index}
              className="text-2xl cursor-pointer"
              onClick={() => window.open(link, "_blank", "noopener,noreferrer")}
            >
              <ion-icon name={`logo-${icon}`}></ion-icon>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
