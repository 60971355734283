import React from "react";

export default function BannerHeader({
  title,
  tagline,
  image,
  backgroundColor,
}) {
  return (
    <div
      className={`lg:h-[60vh] h-[40vh]  flex lg:justify-between justify-center items-center lg:px-14 relative overflow-hidden`}
      style={{ background: backgroundColor }}
    >
      <div className="lg:pl-8 relative z-30 lg:text-left text-center">
        <h1 className="lg:text-4xl text-xl text-white font-bold mb-4">
          {title}
        </h1>
        <p className="text-white font-normal lg:text-lg text-sm lg:w-[65%]">{tagline}</p>
      </div>
      <div className="lg:h-[60vh] lg:top-0 lg:right-0 absolute top-5 md:-right-[5%] -right-[40%] h-[40vh] scale-105">
        <img src={image} alt="" className="h-full object-cover" />
      </div>
    </div>
  );
}
