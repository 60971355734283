export const packageContentSilver = [
    {
        points: "Security Information and Event Management"
    },
    {
        points: "Automated Log aggregation and correlation"
    },
    {
        points: "Automated Critical event alerting & Reporting"
    },
    {
        points: " Collects security event data 24/7 from the customer's standard Connectors"
    },
    {
        points: "Incident & Event Reporting (10 triage/Month)"
    },
    {
        points: "Customer is responsible for analysis and subsequent actions (e.g., incident response)"
    },
    {
        points: "Only standard event sources"
    },
]
export const packageContentGold = [
    {
        points: "Everything in Silver Tier plus"
    },
    {
        points: "Critical event triaging"
    },
    {
        points: "Threat Intelligence"
    },
    {
        points: "Limited reports on request"
    },
    {
        points: "Half Yearly Threat Hunting"
    },
    {
        points: "Security Monitoring 8X5"
    },
    {
        points: "Cyber analyst will analyze and interpret alerts"
    },
    {
        points: "Analyst will triage and classify"
    },
    {
        points: "If determined to be anomalous, Analyst will notify customer for remediation"
    },
    {
        points: "Analyst will only provide assistance on alerts generated"
    },

]
export const packageContentPlatinum = [
    {
        points: "Everything in Silver Tier plus Critical asset identification"
    },

    {
        points: "Event Triaging & Reporting"
    },
    {
        points: "Continuous Threat Intelligence"
    },
    {
        points: "Quarterly Threat Hunting"
    },
    {
        points: "Enhanced collection and response connectors"
    },
    {
        points: "Custom log source integration"
    },
    {
        points: "Forensic investigation"
    },
    {
        points: "Unlimited Reports on request Security"
    },
    {
        points: "Monitoring 24X7"
    },
    {
        points: "Tailored log analysis and advanced correlation (endpoint, payload analysis, logs) & alerts"
    },

]
export const blueTeamAOS = [
    {
        content: "Design and Implementation of SIEM/Data Technologies",
    },
    {
        content: "Threat Intelligence and Security Analytics",

    },
    {
        content: "Security Operations Center (SOC) Assessment",

    },
    {
        content: "Compromise Assessment",

    },
    {

        content: "Situational Awareness Reviews",
    },
    {
        content: "Ongoing Enablement Services",

    },
    {
        content: "Security Configuration Assessments",

    },
    {
        content: "Advanced Threat Hunting",

    },
    {

        content: "Advanced Ad-hoc Incident Response and Remediation",
    }
]
export const redTeamAOS = [
    {
        content: "VA/PT",
    },
    {
        content: "Internal and External Infrastructure Penetration Testing",
    },
    {
        content: "Mobile Application Penetration Testing",
    },
    {
        content: "Network Security Architecture Review",
    },
    {
        content: "Security Configuration Review",
    },
    {
        content: "Security Code Review",
    },
]