import React from "react";
import { Link } from "react-router-dom";
import { transformationInfo } from "../../utils/data/content/Content";

export default function TransformationCard() {
  return (
    <div className="flex lg:flex-row flex-col justify-center items-center gap-5 mt-10 ">
      {transformationInfo.map(({ title, img, tag, path }) => (
        <div className="bg-white rounded-lg px-5 lg:py-5 py-2 min-h-[180px] lg:w-[30%] md:w-[600px] sm:w-[80%] cursor-pointer transation duration-200 ease-out lg:mb-0  hover:-translate-y-2">
          <Link to={path}>
            <div>
              <img src={img} alt="ActiveCyberDefence" />
            </div>
            <div>
              <h5 className="font-semibold mt-2 lg:text-base text-sm">
                {title}
              </h5>
            </div>
            <div>
              <p className="mt-3 text-[13px]">{tag}</p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
