import React, { useEffect } from "react";
import Footer from "./Footer";
import NavBar from "./navbar/NavBar";
import { Routes, Route } from "react-router-dom";
import { routes } from "../utils/service/Routes";


export default function Layout() {
  return (
    <div className="relative">
      <NavBar />
      <div className="layoutConatiner">
        <Routes>
          {routes.map(
            ({ layout, pages }) =>
              layout === "pages" &&
              pages.map(({ path, element }) => (
                <Route exact path={path} element={element} key={element} />
              ))
          )}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
