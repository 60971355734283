import React from "react";
import { styles } from "../../utils/style/style";
import homebanner from "../../utils/images/banner/homebanner.jpg";
import check from "../../utils/images/icons/check.jpg";
import { motion } from "framer-motion";
import {
  slideIn,
  staggerContainer,
  textVariant,
  textVariant2,
} from "../../utils/motion/motion";
export default function Intro() {
  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      className={`${styles.innerpagepaddings}`}
    >
      <div className={`grid lg:grid-cols-2 place-content-center`}>
        <div className="lg:mt-48 lg:text-left mt-5 text-center">
          <h2 className="lg:text-4xl text-2xl font-bold text-black lg:leading-[45px] mb-10">
            The best complete cyber protection, securing your business from
            current and future threats.
          </h2>
          <p className="text-lg font-medium mb-12">
            Enterprise-class Advanced Cybersecurity Solutions to detect and
            mitigate threats at all levels.
          </p>
        </div>
        <motion.div
          // variants={slideIn('right','tween',0.2,1)}
          // initial="hidden"
          // whileInView="show"
          className=" flex justify-center"
        >
          <img src={homebanner} alt="homebanner" className="h=[90%] w-[90%]" />
        </motion.div>
      </div>
      <div className="w-full flex ">
        <div className="mb-10 lg:ml-0 text-center px-4 py-5 homeBoxShadow">
          <h1 className="font-bold mb-1 text-2xl text-primaryBlue">Sectors</h1>
          <p className="font-semibold text-base leading-[20px] ">
            Government, <br /> Insurance, Utility, <br /> and more...
          </p>
        </div>
        <div className="lg:ml-24 hidden lg:block">
          <img src={check} alt="" />
        </div>
      </div>
    </motion.div>
  );
}
