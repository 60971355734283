import React from "react";
import {
  Outlier_Detection,
  Process_Identification,
  Response_Action,
} from "./content";
import ListCard from "../../../components/card/ListCard";
import coverImage from "../../../utils/images/banner/Success Stories/Img-1.png";

export default function AnalyticsNetwork() {
  return (
    <div className="flex justify-center items-center flex-col my-32">
      <div className="text-center">
        <h1 className="text-2xl leading-10">
          Uses Advanced{" "}
          <span className="font-semibold">
            Analytics <br />
          </span>{" "}
          to Track Abnormal <span className="font-semibold">Network</span>{" "}
          Activity
        </h1>
      </div>
        <img src={coverImage} alt="" className="lg:w-[15%] md:w-[35%] w-[60%] mt-5" />
      {/* imageConatiner */}
      <div></div>
      {/* imageConatiner */}
      <div className="backgroundAbout grid lg:grid-cols-3 grid-cols-1 mt-10 gap-10 py-5 lg:px-32 p-5">
        <ListCard title={"Outlier Detection"} list={Outlier_Detection} />
        <ListCard
          title={"Process and Actor Identification"}
          list={Process_Identification}
        />
        <ListCard title={"Response Action"} list={Response_Action} />
      </div>
    </div>
  );
}
