

export const navMenu = [
  {
    id: "m001",
    mainMenu: "Home",
    path: "/home",
  },
  {
    id: "m002",
    mainMenu: "Why CDC",
    path: "/aboutus",
  },
  {
    id: "m003",
    mainMenu: "Services",
    path: "/service/soc",
  },
  {
    id: "m004",
    mainMenu: "Platform",
    path: "/platform/siemplatform",
  },
];
export const footerMenu = [
  {
    id: "m001",
    mainMenu: "Home",
    path: "/home",
  },
  {
    id: "m002",
    mainMenu: "Why CDC",
    path: "/aboutus",
  },
  {
    id: "m003",
    mainMenu: "Platform",
    path: "/platform/siemplatform",
  },
];

