import React from "react";

import BannerHeader from "../../components/header/BannerHeader";
import Banner from "../../utils/images/banner/TechnicalDocumentation/Tech Documentation- Banner.png";
import ITSOC from "../../utils/images/banner/TechnicalDocumentation/IT SOC.png";
import SOC from "../../utils/images/banner/TechnicalDocumentation/Img-1.png";
import PDA from "../../utils/images/banner/TechnicalDocumentation/Img-2.png";
import OTSOC from "../../utils/images/banner/TechnicalDocumentation/OT SOC.png";

export default function TechnicalDocumentation() {
  return (
    <div>
      {" "}
      <BannerHeader
        title="Technical Documentation"
        tagline="Use this page to review the framework and architecture behind the
        CDC-On® technology and platform."
        image={Banner}
        backgroundColor="#04002d"
      />
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <p className="text-[#6993FF] my-5">Security Operations Center</p>
          <h1 className="text-2xl leading-10">
            Framework for Implementing <br /> Hybrid Integrated
            <span className="font-semibold"> CDC-ON SOC</span>{" "}
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={SOC} alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <p className="text-[#6993FF] my-5">CDC-ON</p>
          <h1 className="text-2xl leading-10">
            <span className="font-semibold">IT SOC</span> Architecture
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={ITSOC} alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <p className="text-[#6993FF] my-5">CDC-ON</p>
          <h1 className="text-2xl leading-10">
            <span className="font-semibold">OT SOC</span> Architecture
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={OTSOC} alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <p className="text-[#6993FF] my-5">CDC-ON</p>
          <h1 className="text-2xl leading-10">
            Platform <span className="font-semibold">Deployment</span>{" "}
            Architecture
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={PDA} alt="" />
        </div>
      </div>
    </div>
  );
}
