export const Outlier_Detection = [
    {
        content: "Monitors remote outbound TCP connections"
    },
    {
        content: "Detects uncommon port Usage"
    },
    {
        content: "Detects outliers using advanced algorithms"
    },
    {
        content: "Gathers remate destination details using automated response methods"
    },
]
export const Process_Identification = [
    {
        content: "CDC-CN uses relational analytics to identify and pin down process owners"
    },
    {
        content: "Identifies malicious programs by correlating file hash, process path, usage trend, etc"
    },
    {
        content: "Identifies payload delivery by monitoring user activity"
    },
    {
        content: "Looks for similar attack patterns within n/w"
    },
]
export const Response_Action = [
    {
        content: "Isolates infected machines"
    },
    {
        content: "Performs evidence"
    },
    {
        content: "collection on infected machines"
    },
    {
        content: "Enforces security measures to block any communication to/from attacker IP"
    },
]
export const Bot_Efficiency = [
    {
        content: "Health and utilization"
    },
    {
        content: "ROI Statistics"
    },
    {
        content: "Availability statistics -Bot & dependencies"
    },
    {
        content: "Auto recovery using orchestration"
    },
    {
        content: "Automated ticketing & service management Support leading automation tools"
    },
    {
        content: "Bot capacity recommendations"
    },
]
export const Bot_Security = [
    {
        content: "Bot farm vulnerability statistics"
    },
    {
        content: "Cyber attacks detection towards bots"
    },
    {
        content: "Unexpected network activities"
    },
    {
        content: "Unexpected user behavior"
    },
    {
        content: "Vulnerable payload transfers from/to bots"
    },
    {
        content: "Bot farm threat posture"
    },
    {
        content: "Bot security recommendations"
    },
]
export const Bot_Insights = [
    {
        content: "Failure root cause identification"
    },
    {
        content: "Savings in comparison with health"
    },
    {
        content: "Effective utilization measures"
    },
    {
        content: "Projection of underutilized bots"
    },
    {
        content: "Bot farm executive dashboards"
    },
    {
        content: "Early warning on threats using Ti"
    },
    {
        content: "Proactive failure detection and recovery"
    },

]

export const Security_Analytics = [
    {
        content: "Enhanced security monitoring coverage"
    },
    {
        content: "Reduce mean-time-to-detection"
    },
    {
        content: "Insight to organization security posture in real-time"
    },
    {
        content: "Advanced correlation and monitoring"
    },
    {
        content: "High speed log retrieval and historical data search"
    },
    {
        content: "Real-time alerting"
    },
]

export const SA_Monitoring = [
    {
        content: "Advanced drill down dashboards"
    },
    {
        content: "Automate detection and remediation of threats"
    },
    {
        content: "Visual Analytics Automatic alerts"
    },
    {
        content: "Automated triaging/response actions"
    },
    {
        content: "Vulnerability detection"
    },
    {
        content: "KPI Monitoring, SOC+NOC"
    },

]

export const ROI = [
    {
        content: "Other SIEMAnalytics solution are high in cost"
    },
    {
        content: "Ease to enhance the infrastructure and upgrade"
    },
]

export const Application_Performance_Insights = [
    {
        content: "Improve App performance"
    },
    {
        content: "Reduce mean - time - to - resolution "
    },
    {
        content: "App usage insight "
    },
    {
        content: "Comply with SLA"
    },
    {
        content: "End - User Experience"
    },
    {
        content: "Transaction Performance"
    },
    {
        content: " Trace Details"
    },


]
export const DevSecOps_Monitoring = [
    {
        content: " CVCD pipeline monitoring "
    },
    {
        content: "Automate detection and remediation of problems"
    },
    {
        content: "Visualize deployments"
    },
    {
        content: "Automatic alerting"
    },
    {
        content: "Automated testing visualization"
    },
    {
        content: "Vulnerability detection"
    },
    {
        content: " KPI Monitoring"
    }
]
export const APMMonitoring_ROI = [
    {
        content: "APM tools have been very expensive and targeted at only large enterprises CDC one focus on SMB & Large Enterprises"
    },
    {
        content: "The cost of downtime"
    },
    {
        content: "SLA Requirements"
    },
    {
        content: "Increasing Tearn Productivity"
    },

]