import React from "react";
import Banner from "../../utils/images/banner/SIEM Platform Comparison/SIEM- Banner.png";
import CoverImage from "../../utils/images/banner/SIEM Platform Comparison/SIEM-Image.png";
import BannerHeader from "../../components/header/BannerHeader";
import { Platform_Comparison } from "./content";
import {
  AvailableIcon,
  NotAvailableIcon,
} from "../../utils/images/icons/icons";

export default function SIEMPlatform() {
  console.log(Platform_Comparison?.map((data) => data));
  return (
    <div>
      <BannerHeader
        title="SIEM Platform Comparison"
        tagline="Take a look at how CDC-On® SIEM compares to the rest of the competition including leading brands."
        image={Banner}
        backgroundColor="#111233"
      />
      <div className="flex justify-center items-center flex-col">
        <h1 className="text-2xl my-20">
          <span className="font-bold">Platform </span> Comparison (SIEM)
        </h1>
        <img src={CoverImage} alt="" className="lg:w-[15%]  w-[40%]" />
      </div>
      <div className="backgroundAbout">
        <div class="container mx-auto px-4 sm:px-8">
          <div class="py-8">
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden bg-white p-2">
                <table class="min-w-full leading-normal rounded-lg overflow-hidden">
                  <thead>
                    <tr className="bg-[#6993FF] text-white">
                      <th class="p-2 border-b-2 border-r border-gray-200  text-left text-xs font-semibold tracking-wider"></th>
                      <th class="px-5 py-3 border-b-2 border-r w-[100px] border-gray-200  text-left text-xs font-semibold tracking-wider">
                        Validation Parameters
                      </th>
                      <th class="px-5 py-3 border-b-2 border-r border-gray-200  text-left text-xs font-semibold tracking-wider">
                        CDC-ON
                      </th>
                      <th class="p-2 border-b-2 border-r border-gray-200  text-left text-xs font-semibold tracking-wider">
                        Leading brand 1
                      </th>
                      <th class="p-2 border-b-2 border-r border-gray-200  text-left text-xs font-semibold tracking-wider">
                        Leading brand 2
                      </th>
                      <th class="p-2 border-b-2 border-r border-gray-200  text-left text-xs font-semibold tracking-wider">
                        Leading brand 3
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-slate-100">
                    {Platform_Comparison?.map(
                      (
                        {
                          validationParameters,
                          CDCON,
                          splunkEnt,
                          alienVault,
                          logRhythm,
                        },
                        index
                      ) => (
                        <tr className="cursor-pointer hover:bg-[#E4EBFF] text-center">
                          <td class="p-2 border-b border-r border-gray-200 text-sm ">
                            {index + 1}
                          </td>
                          <td class="px-5 py-5 border-b border-r border-gray-200 text-sm w-[500px] text-left">
                            {validationParameters}
                          </td>
                          <td class="p-2 border-b border-r border-gray-200 text-sm">
                            <div className="flex justify-center items-center">
                              {CDCON ? <AvailableIcon /> : <NotAvailableIcon />}
                            </div>
                          </td>
                          <td class="p-2 border-b border-r border-gray-200 text-sm ">
                            <div className="flex justify-center items-center">
                              {splunkEnt ? (
                                <AvailableIcon />
                              ) : (
                                <NotAvailableIcon />
                              )}
                            </div>
                          </td>
                          <td class="p-2 border-b border-r border-gray-200 text-sm ">
                            <div className="flex justify-center items-center">
                              {alienVault ? (
                                <AvailableIcon />
                              ) : (
                                <NotAvailableIcon />
                              )}
                            </div>
                          </td>
                          <td class="p-2 border-b border-r border-gray-200 text-sm ">
                            <div className="flex justify-center items-center">
                              {logRhythm ? (
                                <AvailableIcon />
                              ) : (
                                <NotAvailableIcon />
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
