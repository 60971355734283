import React from "react";
import { Bot_Efficiency, Bot_Insights, Bot_Security } from "./content";
import ListCard from "../../../components/card/ListCard";
import coverImage from "../../../utils/images/banner/Success Stories/Img-2.png";


export default function BotManagement() {
  return (
    <div className="flex justify-center items-center flex-col my-32">
      <div className="text-center">
        <h1 className="text-2xl leading-10">
          <span className="font-semibold">Bot</span> Management & Analytics
        </h1>
      </div>
      <img src={coverImage} alt="" className="lg:w-[15%] md:w-[35%] w-[60%] mt-5" />
      {/* imageConatiner */}
      <div></div>
      {/* imageConatiner */}
      <div className="backgroundAbout grid lg:grid-cols-3 grid-cols-1 mt-10 gap-10 py-5 lg:px-32 p-5">
        <ListCard title={"Bot Efficiency"} list={Bot_Efficiency} />
        <ListCard title={"Bot Security"} list={Bot_Security} />
        <ListCard title={"Bot Insights"} list={Bot_Insights} />
      </div>
    </div>
  );
}
