import React from "react";
import AOSBanner from "../../../utils/images/banner/Success Stories/bannerSS.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import AnalyticsNetwork from "./AnalyticsNetwork";
import BotManagement from "./BotManagement";
import SecurityAnalytics from "./SecurityAnalytics";
import APMMonitoring from "./APMMonitoring";
import AdvancedThreat from "./AdvancedThreat";
import BannerHeader from "../../../components/header/BannerHeader";
import APMCoverImage from "../../../utils/images/banner/Success Stories/APM.png";
import CDCActionCoverImage from "../../../utils/images/banner/Success Stories/CDC Action.png";
import RPABOTCoverImage from "../../../utils/images/banner/Success Stories/RPA BOT.png";
import SOARCoverImage from "../../../utils/images/banner/Success Stories/SOAR.png";
import Tick from "../../../utils/images/banner/Success Stories/Tick-icon.png";
import TestimonialsImg from "../../../utils/images/banner/Success Stories/Testimonials-icon.png";
import PCImage from "../../../utils/images/banner/Success Stories/Img-5.png";
import {
  Fulltestimonials,
  Testimonials1,
  Testimonials2,
  allsuccessStories,
  successStories1,
  successStories2,
  successStories3,
  successStories4,
  successStories5,
} from "../../../utils/data/content/Content";

export default function SuccessStories() {
  return (
    <div>
      <BannerHeader
        title="Ciber Digita Success Stories"
        tagline="Check out the companies below that have incorporated CDC-ON/Services
        for their organizations and have realized massive cost savings."
        image={AOSBanner}
        backgroundColor="#050624"
      />
      {/* Carousel */}
      <div className="mt-20 mb-10 text-center">
        <h1 className="font-bold text-xl">Success Stories</h1>
      </div>
      <div className="md:block hidden">
        <Carousel showThumbs={false} infiniteLoop={true}>
          <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
            {successStories1.map(({ title, dec }, index) => (
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
            {successStories2.map(({ title, dec }, index) => (
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
            {successStories3.map(({ title, dec }, index) => (
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
            {successStories4.map(({ title, dec }, index) => (
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3  py-10 px-20 gap-5 justify-center items-center">
            {successStories5.map(({ title, dec }, index) => (
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            ))}
          </div>
        </Carousel>
      </div>
      <div className="md:hidden block">
        <Carousel showThumbs={false}>
          {allsuccessStories.map(({ title, dec }, index) => (
            <div className="grid  grid-cols-1 py-10 px-10 gap-5 justify-center items-center">
              <div
                className="shadow-xl p-5 text-left  h-[180px] rounded-xl"
                key={index}
              >
                <img src={Tick} alt="" className="tickIcon" />
                <h1 className="font-bold mb-3">{title}</h1>
                <p>{dec}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {/* Carousel End */}
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <h1 className="text-2xl leading-10">
            Ciber Digita{" "}
            <span className="font-semibold">
              Custom Use-Cases <br />
            </span>{" "}
            Advanced Threat Hunting and
            <span className="font-semibold"> SOAR</span>
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={SOARCoverImage} alt="" />
        </div>
      </div>
      <AnalyticsNetwork />
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <h1 className="text-2xl leading-10">
            <span className="font-semibold">RPA BOT</span> Farm Activity
            Monitoring
          </h1>
        </div>
        <div className=" w-[85%] mt-10">
          <img src={RPABOTCoverImage} alt="" />
        </div>
      </div>
      <BotManagement />
      <div className="bg-backgroundBlueShade lg:p-20 p-5 grid lg:grid-cols-2 grid-cols-1">
        <div>
          <h1 className="text-2xl font-bold mb-10">
            Design and Implement <br /> Security Analytics Platform
          </h1>
          <p>
            Our clients rely on CDC-On and critical features like security,
            alerting, and machine learning. CDC-On ingests, correlates, and
            analyzes vast quantities of information from thousands of systems
            across all of its member institutions in order to detect security
            breaches and cyber threats.
          </p>
          <p className="mt-5">
            Client SCC security engineers then provide rapid, actionable
            intelligence back to its members so they can mitigate risks, close
            security gaps and prevent future attacks.
          </p>
        </div>
        <div className="flex justify-center items-center">
          <img src={PCImage} alt="" className="lg:w-[40%] md:w-[50%] w-[60%]" />
        </div>
      </div>
      <SecurityAnalytics />
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <h1 className="text-2xl leading-10">
            DevSecOps and <span className="font-semibold">APM</span>
          </h1>
        </div>
        <div className="w-[85%]  mt-10">
          <img src={APMCoverImage} alt="" />
        </div>
      </div>
      <APMMonitoring />
      <div className="flex justify-center items-center flex-col my-32">
        <div className="text-center">
          <h1 className="text-2xl leading-10">
            Ciber Digita <span className="font-semibold">in Action</span>{" "}
          </h1>
        </div>
        <div className="w-[85%]  mt-10">
          <img src={CDCActionCoverImage} alt="" />
        </div>
      </div>
      <AdvancedThreat />
      {/* Carousel */}
      <div className="text-center md:block hidden">
        <h1 className="my-10 font-bold text-xl">Testimonials</h1>
        <Carousel showThumbs={false}>
          <div className="grid  grid-cols-2 py-10 px-20 gap-5 justify-center items-center">
            {Testimonials1.map(({ name, designation, says }, index) => (
              <div
                className="shadow-xl p-5 text-left h-[230px] rounded-xl relative"
                key={index}
              >
                <img
                  src={TestimonialsImg}
                  alt=""
                  className="testimonialsImg absolute top-3 right-5"
                />
                <h1 className="font-bold mb-3">{name}</h1>
                <h5 className="mt-2 mb-5 text-primaryBlue">{designation}</h5>
                <p>{says}</p>
              </div>
            ))}
          </div>
          <div className="grid  grid-cols-2 py-10 px-20 gap-5 justify-center items-center">
            {Testimonials2.map(({ name, designation, says }, index) => (
              <div
                className="shadow-xl p-5 text-left h-[230px] rounded-xl relative"
                key={index}
              >
                <img
                  src={TestimonialsImg}
                  alt=""
                  className="testimonialsImg absolute top-3 right-5"
                />
                <h1 className="font-bold mb-3">{name}</h1>
                <h5 className="mt-2 mb-5 text-primaryBlue">{designation}</h5>
                <p>{says}</p>
              </div>
            ))}
          </div>
        </Carousel>
      </div>
      <div className="text-center md:hidden block">
        <h1 className="my-10 font-bold text-xl">Testimonials</h1>
        <Carousel showThumbs={false}>
          {Fulltestimonials.map(({ name, designation, says }, index) => (
            <div
              className="grid  grid-cols-1 py-10 px-10 gap-5 justify-center items-center"
              key={index}
            >
              <div className="shadow-xl p-5 text-left  h-min-[180px] rounded-xl relative">
                <img
                  src={TestimonialsImg}
                  alt=""
                  className="testimonialsImg absolute top-3 right-5"
                />
                <h1 className="font-bold mb-3">{name}</h1>
                <h5 className="mt-2 mb-5 text-primaryBlue">{designation}</h5>
                <p>{says}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {/* Carousel End */}
    </div>
  );
}
