import React from "react";
import BulletIcon from "../../../utils/images/icons/Bullet-icon 1.png";
import BannerTop from "../../../utils/images/banner/Success Stories/Banner 1.png";
import BannerBottom from "../../../utils/images/banner/Success Stories/Banner 2.png";

export default function AdvancedThreat() {
  return (
    <div className="relative text-white bg-[#11002E] overflow-hidden">
      <div className="absolute -top-10 -right-16 w-full flex justify-end z-30">
        <img src={BannerTop} alt="" className="scale-90" />
      </div>
      <div className="absolute bottom-0 right-0 w-full flex justify-end z-30">
        <img src={BannerBottom} alt="" className="h-[365px]" />
      </div>
      <div className="lg:p-20 p-5 relative z-50">
        <h1 className="font-bold text-xl mb-5">
          Advanced Threat hunting and{" "}
          <span className="font-extrabold">SOAR</span>{" "}
        </h1>
        <p className="lg:w-[55%] md:w-[60%]">
          The threat landscape is ever changing in IT environments. Attacks and
          attack vectors are hard to detect with traditional detection rules and
          signatures. Cyber Security Analysts are expected to be armed with
          threat hunting skills to tackle such challenging situations.
        </p>
        <p className="py-5 lg:w-[55%] md:w-[60%]">
          CDC-ON® provides advanced analytics and workflows which makes threat
          hunting easy and effective. Attackers use infinite methods to
          infiltrate networks by exploiting vulnerabilities within network or
          people. Phishing emails, web ads, etc. are the most common methods
          employed by attackers. Latest security reports show high success rates
          for these attack mechanisms even today. Once getting into a host, the
          attacker hides himself and the malware within the victim's machine
          Hidden within usual traffic, the attacker exfiltrates data using
          different protocols and services. This makes traditional detection
          mechanisms ineffective against such attacks
        </p>
        <p className="lg:w-[75%] md:w-[80%]">
          CDC-ON® uses advanced analytics to detect outliers from seemingly
          usual traffic. CDC-ON can detect unusual traffic from baselined usual
          traffic and expected behaviors. Analysts can look into multiple
          dashboards that provide high visibility into user and network
          behaviors. Once unusual traffic is detected tracking down the true
          source process, host and user becomes the next step in threat hunting.
          This is where relational analytics of a SIEM tool can help an analyst
          without misdirecting him. CDC-ON® can provide instant visibility into
          process owner and related host activities. By correlating malicious
          traffic and process owner, an analyst can now look into malware
          delivery chains Observing user activity and payload inspection during
          a specified time window will give insight into the method of delivery
          whether email or web traffic. Analysts can also look for similar
          patterns of traffic within the network to track clown malware spread.
        </p>
        <p className="my-10">
          Once infection is confirmed, the next step is response action. CDC-ONE
          IS integrated with SOAR platforms thus providing advanced responses
          like:
        </p>
        <ul className="mt-8 grid gap-y-3">
          <li className="flex gap-x-2 font-semibold">
            <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
              <img
                src={BulletIcon}
                alt=""
                className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
              />
            </div>
            <div className="lg:w-[100%] w-[90%]">Network isolation of host</div>
          </li>
          <li className="flex gap-x-2 font-semibold">
            <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
              <img
                src={BulletIcon}
                alt=""
                className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
              />
            </div>
            <div className="lg:w-[100%] w-[90%]">
              Evidence collection from infected host
            </div>
          </li>
          <li className="flex gap-x-2 font-semibold">
            <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
              <img
                src={BulletIcon}
                alt=""
                className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
              />
            </div>
            <div className="lg:w-[100%] w-[90%]">
              Enforce security measures by blocking malicious traffic at
              perimeter level
            </div>
          </li>
          <li className="flex gap-x-2 font-semibold">
            <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
              <img
                src={BulletIcon}
                alt=""
                className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
              />
            </div>
            <div className="lg:w-[100%] w-[90%]">
              Send ticket to an ITSM solution
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
