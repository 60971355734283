import React from "react";
import AOSBanner from "../../utils/images/banner/AOS/AOS-Banner.png";
import bannerAOS from "../../utils/images/banner/AOS/bannerAOS.png";
import bannerAOS2 from "../../utils/images/banner/AOS/AOS-img-2.png";
import AosbckBanner from "../../utils/images/banner/AOS/EUC-img-2.png";
import BulletIcon from "../../utils/images/icons/Bullet-icon 1.png";
import Advanced from "../../utils/images/icons/AOS/Advanced -icon.png";
import Audits from "../../utils/images/icons/AOS/Audits, Security-icon.png";
import Business from "../../utils/images/icons/AOS/Business continuity-icon.png";
import CustomSecurity from "../../utils/images/icons/AOS/Custom Security-icon.png";
import GRC from "../../utils/images/icons/AOS/GRC- ISO -icon.png";
import Identity from "../../utils/images/icons/AOS/Identity -icon.png";
import { blueTeamAOS, redTeamAOS } from "./content";
import BannerHeader from "../../components/header/BannerHeader";

export default function AOS() {
  return (
    <div>
      <BannerHeader
        title="Add-On and Advanced Services (AOS)"
        tagline="Add-on cybersecurity services: red-blue teaming, VA/PT,
        audits, custom security development."
        image={AOSBanner}
        backgroundColor="#01041B"
      />
      <div className="px-10 mb-10">
        <div className="text-center mt-10">
          <h2 className="text-blue-500 font-semibold">AOS</h2>
          <h1 className="text-xl font-bold">Add-On Services </h1>
        </div>

        <div className="flex justify-center my-14 md:flex-row flex-col">
          <div className="md:order-2 order-1 flex justify-center flex-col items-center text-center px-14">
            <img src={bannerAOS} className="w-[80%]" alt="" />
          </div>
          <div className="md:flex hidden justify-center items-center flex-col gap-y-10 md:order-1 order-2">
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={CustomSecurity} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Custom Security Software Development
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Advanced} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Advanced Integration Services
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={GRC} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                GRC/ ISO 27001 Implementation Advisory
              </p>
            </div>
          </div>

          <div className="md:flex hidden justify-center items-center flex-col gap-y-10 order-3">
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img
                  src={Identity}
                  alt=""
                  className="w-[65%] h-[65%] object-contain"
                />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Identity and Access Management
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[120px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Audits} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">
                Audits, Security Program and Strategy
              </p>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-[80px]">
              <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                <img src={Business} alt="" />
              </div>
              <p className="text-[10px] font-semibold mt-1">BCP/DR</p>
            </div>
          </div>

          <div className="md:hidden order-2 flex justify-center gap-5 mt-10">
            <div className="flex justify-center items-center flex-col gap-y-10 md:order-1 order-2">
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={CustomSecurity} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Custom Security Software Development
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Advanced} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Advanced Integration Service
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={GRC} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  GRC/ ISO 27001 Implementation Advisory
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center flex-col gap-y-10 order-3">
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Identity} alt="" className="w-[65%] h-[65%]" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Identity and Access Management
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[120px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Audits} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">
                  Audits, Security Program and Strategy
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center w-[80px]">
                <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
                  <img src={Business} alt="" />
                </div>
                <p className="text-[10px] font-semibold mt-1">BCP/DR</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center text-center w-full">
          <div className="w-[90px] h-[90px] bg-[#F3F4F6] rounded-[50%] flex justify-center items-center">
            <img src={CustomSecurity} alt="" />
          </div>
          <p className="text-[10px] font-semibold mt-1">
            Custom Security Software Development
          </p>
        </div>
      </div>

      <div className="bg-backgroundBlueShade relative">
        <div className="absolute top-0 right-0 w-full z-20">
          <img
            src={AosbckBanner}
            alt=""
            className="w-[100%] md:h-[350px] h-[150px]"
          />
        </div>
        <div className="py-10 flex justify-center items-center flex-col relative z-40 pt-20">
          <h1 className="lg:text-2xl text-sm mb-14">
            Advanced Professional Services
          </h1>
          <img src={bannerAOS2} alt="" className="lg:w-[20%] w-[40%]" />
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 lg:p-20 p-5 ">
          <div className="shadow-xl bg-white rounded-xl p-5">
            <h1 className="ml-2 font-semibold text-2xl">Blue Team</h1>
            <ul className="mt-8 grid gap-y-3">
              {blueTeamAOS?.map(({ content }) => (
                <li className="flex gap-x-2 font-semibold">
                  <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
                    <img
                      src={BulletIcon}
                      alt=""
                      className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
                    />
                  </div>
                  <div className="lg:w-[100%] w-[90%]">{content}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="shadow-xl bg-white rounded-xl p-5">
            <h1 className="ml-2 font-semibold text-2xl">Red Team</h1>
            <ul className="mt-8 grid gap-y-3">
              {redTeamAOS?.map(({ content }) => (
                <li className="flex gap-x-2 font-semibold">
                  <div className="mr-2 lg:w-[14px] lg:h-[14px] lg:mt-0 mt-1">
                    <img
                      src={BulletIcon}
                      alt=""
                      className="lg:w-[20px] lg:h-[20px] h-[15px] w-[15px] object-cover"
                    />
                  </div>
                  <div className="lg:w-[100%] w-[90%]">{content}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
