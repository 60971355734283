import React from "react";
import EUCMAINBanner from "../../utils/images/banner/EUC/EUC-banner.png";
import EUCBanner from "../../utils/images/banner/EUC/EUC-img-1.png";
import Packaging from "../../utils/images/icons/EUC/Application Packaging-icon.png";
import MobileDevice from "../../utils/images/icons/EUC/Mobile Device Management-icon.png";
import PatchManagement from "../../utils/images/icons/EUC/Patch Management-icon.png";
import Vulnerability from "../../utils/images/icons/EUC/Vulnerability Management-icon.png";
import Windows from "../../utils/images/icons/EUC/Windows OS Migration-icon.png";
import BannerHeader from "../../components/header/BannerHeader";



export default function EUC() {
  return (
    <div> 
      
      <BannerHeader
        title="End User Computing (EUC)"
        tagline="End-user computing: VA, patch management, application management"
        image={EUCMAINBanner}
        backgroundColor="#01041B"
      />
      <div className="pt-10">
        <div className="text-center">
          <h2 className="text-blue-500 font-semibold">EUC</h2>
          <h1 className="font-extrabold text-2xl">
            End-user Computing Services
          </h1>
        </div>
        <div className="flex justify-center  my-2">
          <img className="lg:w-[20%] md:w-[40%] w-[60%]" src={EUCBanner} alt="" />
        </div>
        <div className="backgroundAbout lg:px-20 lg:py-10 py-0 px-5 my-10">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-5">
            <div className="bg-white shadow-lg px-5 py-20  text-center rounded-xl">
              <div className="flex justify-center items-center">
                <img src={Vulnerability} alt="" />
              </div>
              <h1 className="font-bold my-2">Vulnerability Management</h1>
              <p className="text-sm">
                Security analysis of IT Infrastructure and remediation of
                vulnerabilities.
              </p>
            </div>
            <div className="bg-white shadow-lg px-5 py-20 text-center rounded-xl">
              <div className="flex justify-center items-center">
                <img src={PatchManagement} alt="" />
              </div>
              <h1 className="font-bold my-2">Patch Management</h1>
              <p className="text-sm">
                Windows OS monthly security patching to ensure that devices are
                complaint with latest security updates.
              </p>
            </div>
            <div className="bg-white shadow-lg px-5 py-20 text-center rounded-xl">
              <div className="flex justify-center items-center">
                <img src={Windows} alt="" />
              </div>
              <h1 className="font-bold my-2">Windows OS Migration</h1>
              <p className="text-sm">
                Migration of Windows devices to the latest OS which include
                upgrade/update of Operating System.
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <div className="bg-white shadow-lg px-5 py-20 text-center rounded-xl">
              <div className="flex justify-center items-center">
                <img src={Packaging} alt="" />
              </div>
              <h1 className="font-bold my-2">Application Packaging</h1>
              <p className="text-sm">
                Customizing the applications to incorporate company security
                policies and best practices.
              </p>
            </div>
            <div className="bg-white shadow-lg px-5 py-20 text-center rounded-xl">
              <div className="flex justify-center items-center">
                <img src={MobileDevice} alt="" />
              </div>
              <h1 className="font-bold my-2">Mobile Device Management:</h1>
              <p className="text-sm">
                Administration of mobile devices which include compliance
                policies, device configuration and enrollment. Management of
                Corporate and self-owned devices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
